import { DeleteData, GetData, PostData } from "../../APIcalls";
import { message } from "antd";
import {
  setAddMediaActions,
  setExternalvideoData,
  setThemes,
  setCountriesList,
  setVisibleDelete,
  setthumbsthemes,
  setSubscGroups,
  setContributorData,
  setVideoFile,
  setPendingVideoData,
  setPreviewLink,
  setStoryboard,
  setVisible,
  setthumbsData,
  setmetadataVideo,
  setscreenthumbsData,
  setShowthemes,
  setLoading,
  setVisibleDeleteMultiple,
  setErrorsForm,
  setThumbnailsPathExists,
  setLoadingDelete,
  setVideoDataLoading,
  setVideoLinkLoading
} from "../store/addMediaActions";
import { useAppSelector } from "../../redux/hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
export const AddHooks = () => {
  const [isModalVisible, setisModalVisible] = useState(false);
  const [timer] = useState(0);
  const[Accessible , setAccessible]=useState(true)
  const messageCatch = (() => message.error(`Une erreur s'est produite de notre côté `))
  const messageCatchCurrupted = (() => message.error(`Cette vidéo est corrompue `))
  const userData = useAppSelector(state => state.ReducerUserData.userData)
  const values = useAppSelector(
    (state) => state.addMediaReducer.AddMediaConstraintsData
  );
  const pendingVideo = useAppSelector(
    (state) => state.addMediaReducer.pendingVideo
  );
  const initialThumbnail = useAppSelector(
    (state) => state.addMediaReducer.thumbs
  ); 
  const modules = useAppSelector((state) => state.ReducerUserData.modules);
  const [filesvideos, setFilesVideos] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation()
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  function secondsToHms(d) {
    if (!d) return "00:00:00"
    const displayTime = new Date(d * 1000).toISOString().slice(11, 19);
    return displayTime
  }
  const timeForThumbnail = (time = 0) => {
    const displayTime = new Date(time * 1000).toISOString().slice(11, 23);
    return displayTime ? displayTime : "00:00:00"
  }


  // populate video state
  const populateVideoState = (id, res, fileTitle, cancelToken) => {
    const endDate = res.data?.video ? res.data.video?.endDate : res.data?.endDate
    const startDate = res.data?.video ? res.data.video?.startDate : res.data?.startDate
    const updated_at = res.data?.video ? res.data.video?.updated_at : res.data?.updated_at
    const metadata = res.data?.video ? res.data.video?.metaData : res.data?.metaData
    const parsedMetaData = JSON.parse(metadata)?.contentJson
    handleChangeDate(endDate, "endDate");
    handleChangeDate(startDate, "startDate");
    handleChangeDate(updated_at, "updated_at")
    if (metadata) {
      const durationTime = secondsToHms(parsedMetaData?.streams[0]?.duration ? parsedMetaData?.streams[0]?.duration : parsedMetaData?.format?.duration)
      handleChangeDuration(durationTime, "duration")
      dispatch(setAddMediaActions({
        addMediaConstraintsDataNameChange: "metaData",
        addMediaConstraintsDataValueChange: metadata
      }));
      dispatch(setmetadataVideo(parsedMetaData))
    } else {

    }
    const contributor = res.data?.video ? res.data.video?.contributor : res.data?.contributor
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "contributor",
        addMediaConstraintsDataValueChange: contributor?.id,
      })
    );
    const description = res.data?.video ? res.data.video?.description : res.data?.description
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "description",
        addMediaConstraintsDataValueChange: description,
      })
    );
    const transcription = res.data?.video ? res.data.video?.transcription : res.data?.transcription
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "transcription",
        addMediaConstraintsDataValueChange: transcription,
      })
    );
    const logoUrl = res.data?.video ? res.data.video?.logo : res.data?.logo
    const splitLogoUrl = logoUrl ? logoUrl.split("/") : undefined
    if (splitLogoUrl && splitLogoUrl.length > 1) {
      dispatch(
        setAddMediaActions({
          addMediaConstraintsDataNameChange: "logo",
          addMediaConstraintsDataValueChange: splitLogoUrl[splitLogoUrl.length - 1]
        })
      );
    } else {
      dispatch(
        setAddMediaActions({
          addMediaConstraintsDataNameChange: "logo",
          addMediaConstraintsDataValueChange: logoUrl
        })
      );
    }
    const copyright = res.data?.video ? res.data.video?.copyright : res.data?.copyright
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "copyright",
        addMediaConstraintsDataValueChange: copyright,
      })
    );
    const author = res.data?.video ? res.data.video?.author : res.data?.author
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "author",
        addMediaConstraintsDataValueChange: author,
      })
    );
    const title = res.data?.video ? res.data.video?.title : res.data?.title
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "title",
        addMediaConstraintsDataValueChange: title,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "fileTitle",
        addMediaConstraintsDataValueChange: fileTitle,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "insertDate",
        addMediaConstraintsDataValueChange: new Date().toISOString(),
      })
    );
    const isSecuredOnInternet = res.data?.video ? res.data.video?.isSecuredOnInternet : res.data?.isSecuredOnInternet
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "isSecuredOnInternet",
        addMediaConstraintsDataValueChange: isSecuredOnInternet,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "secure",
        addMediaConstraintsDataValueChange: isSecuredOnInternet,
      })
    );
    const is_accessible = res.data?.video ? res.data.video?.is_accessible : res.data?.is_accessible
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "is_accessible",
        addMediaConstraintsDataValueChange: is_accessible,
      })
    );
    const passwordInternet = res.data?.video ? res.data.video?.passwordInternet : res.data?.passwordInternet
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "passwordInternet",
        addMediaConstraintsDataValueChange: passwordInternet,
      })
    );
    const downloadable = res.data?.video ? res.data.video?.downloadable : res.data?.downloadable
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "downloadable",
        addMediaConstraintsDataValueChange: downloadable,
      })
    );
    const keyWords = res.data?.video ? res.data.video?.keyWords : res.data?.keyWords
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "keyWords",
        addMediaConstraintsDataValueChange: keyWords,
      })
    );
    const themes = res.data?.video ? res.data.video?.themes : res.data?.themes
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "themes",
        addMediaConstraintsDataValueChange: themes?.length
          ? Array.from(themes, ({ id }) => id)
          : [],
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "passwordInternet",
        addMediaConstraintsDataValueChange: passwordInternet,
      })
    );
    const subscriptionGroups = res.data?.video ? res.data.video?.subscriptionGroups : res.data?.subscriptionGroups
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "subscriptionGroups",
        addMediaConstraintsDataValueChange: subscriptionGroups
          .length
          ? Array.from(subscriptionGroups, ({ id }) => id)
          : [],
      })
    );
    dispatch(setShowthemes(themes));
    const secure = res.data?.video ? res.data.video?.secure : res.data?.secure
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "secure",
        addMediaConstraintsDataValueChange: secure,
      })
    );
    const allow_share = res.data?.video ? res.data.video?.allow_share : res.data?.allow_share
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "allow_share",
        addMediaConstraintsDataValueChange: allow_share,
      })
    );
    const isPrivate = res.data?.video ? res.data.video?.isPrivate : res.data?.isPrivate
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "isPrivate",
        addMediaConstraintsDataValueChange: isPrivate,
      })
    );
    const mainPage = res.data?.video ? res.data.video?.mainPage : res.data?.mainPage
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "mainPage",
        addMediaConstraintsDataValueChange: mainPage,
      })
    );
    const offline = res.data?.video ? res.data.video?.offline : res.data?.offline
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "offline",
        addMediaConstraintsDataValueChange: offline,
      })
    );
    const tokens = res.data?.video ? res.data.video?.tokens : res.data?.tokens
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "tokens",
        addMediaConstraintsDataValueChange: tokens,
      })
    );
    const filesVideo = res.data?.video ? res.data.video?.filesVideo : res.data?.filesVideo
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "filesVideo",
        addMediaConstraintsDataValueChange: filesVideo,
      })
    );
    const updatedBy = res.data?.video ? res.data.video?.updatedBy : res.data?.updatedBy
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "updated_by",
        addMediaConstraintsDataValueChange: updatedBy?.firstName + " " + updatedBy?.lastName,
      })
    );
  }

  const updateFilesVideos = (e, action) => {
    if (action === "add") {
      setFilesVideos([...filesvideos, e]);
      dispatch(
        setAddMediaActions({
          addMediaConstraintsDataNameChange: "filesVideo",
          addMediaConstraintsDataValueChange: [...filesvideos, e],
        })
      )
    }
    else if (action === "remove") {
      dispatch(
        setAddMediaActions({
          addMediaConstraintsDataNameChange: "filesVideo",
          addMediaConstraintsDataValueChange: e,
        })
      );
    }
    else {
      setFilesVideos(e);
      const files = e.map(t => {
        const titleSplitted = t.title.split("/")
        const correctTitle = titleSplitted[titleSplitted.length - 1]
        return {
          ...t,
          title: correctTitle
        }
      })
      dispatch(
        setAddMediaActions({
          addMediaConstraintsDataNameChange: "filesVideo",
          addMediaConstraintsDataValueChange: files,
        })
      );
    };
  }
  const updateThemes = (e) => {
    const ids =e.map((x)=>x.value)
    setThemes(Array.from(e, ({ value }) => value));
    dispatch(
      setShowthemes(
        e.length
          ? e.map(el => ({ id: el.value, title: el.label }))
          : []
      )
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "themes",
        addMediaConstraintsDataValueChange: e.length
          ? Array.from(e, ({ value }) => value)
          : [],
      })
    );
  };
  // update country field
  const updateCountries = (e) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "countriesCodes",
        addMediaConstraintsDataValueChange: e.length ? e : [],
      })
    );
  };
  const updatecontributor = (e) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "contributor",
        addMediaConstraintsDataValueChange: e,
      })
    );
  };
  const updateSubsGroups = (e) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "subscriptionGroups",
        addMediaConstraintsDataValueChange: e,
      })
    );
  };
  const handleChangeInputs = (event) => {

    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: event.target.name,
        addMediaConstraintsDataValueChange: event.target.value,
      })
    );
  };
  const handleChangeDate = (event, field) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: field,
        addMediaConstraintsDataValueChange: moment(event).toISOString(),
      })
    );
  };
  const handleChangeDuration = (event, field) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: field,
        addMediaConstraintsDataValueChange: event,
      })
    );
  }
  const getThumbsthemes = async () => {
    await GetData("/theme/thumbs").then((res) => {
      dispatch(setthumbsthemes(res?.data));

    });
  };
  const getSubsGroups = async () => {
    await GetData("/subscription_group/groups").then((res) => {
      let formattedGroups = [];
      res?.data?.data?.groups.forEach((row) => {
        formattedGroups.push({ ...row, label: row.name });
      });
      dispatch(setSubscGroups(formattedGroups));
    });
  };
  const uploadThumb = async (data) => {
    await PostData("/video/video/upload/thumb", data);
  };
  const postupload = async (id) => {
    dispatch({ type: 'SET_LOADING_BTN', payload: true })
    if (!values.contributor) { values.contributor = userData?.id }
    const newValues = { ...values }
    const startDate = new Date(newValues.startDate)?.toISOString();
		const insertDate = new Date(newValues.insertDate)?.toISOString();
     console.log(newValues,"newValuesnewValues");
     
  
    if (newValues.endDate === "") newValues.endDate = Date.now()
    if (newValues.startDate === "") newValues.startDate = new Date().toISOString()
    if (newValues.logo === "") newValues.logo = initialThumbnail.thumbnails
    if (newValues.logo?.includes("https")) newValues.logo = newValues.logo.replace("https:", "").replaceAll(" ", "%20")
    const newDate = new Date(newValues.endDate)
    const returnBack = new Date(newDate).toISOString()
    newValues.endDate = returnBack
    if (!newValues.logo || newValues.logo === "") {
      newValues.logo = newValues.fileTitle.split(".")[0] + ".jpg"
    }
    await PostData("/video/videos/edit/" + id, {
			empMediaVideoForm: {
				...newValues,
				startDate,
				insertDate,
				is_accessible: Accessible,
			},
			id: id,
			secondStep: true,
			sendPushNotif: 0,
		})
			.then((res) => {
				dispatch({ type: "SET_UPDATE_CUSTOM_FIELDS", payload: 0 });
				dispatch({ type: "SET_LOADING_BTN", payload: false });
				if (res.data.code === 200) {
					dispatch(
						setAddMediaActions({
							addMediaConstraintsDataNameChange: "author",
							addMediaConstraintsDataValueChange: "",
						})
					);
					dispatch(
						setAddMediaActions({
							addMediaConstraintsDataNameChange: "showthemes",
							addMediaConstraintsDataValueChange: [],
						})
					);
					history.push("/ws-media/medias");
				} else {
					dispatch(setErrorsForm(true));
				}
			})
			.catch(async () => {
				dispatch({ type: "SET_UPDATE_CUSTOM_FIELDS", payload: 0 });
				message.error(`video upload failed.`);
				dispatch({ type: "SET_LOADING_BTN", payload: false });
			});
  };



  const postuploadurl = async (id) => {
    dispatch({ type: 'SET_LOADING_BTN', payload: true })
    if (!values.contributor) { values.contributor = userData?.id }
    const newValues = { ...values }
    const startDate = convertDate(newValues.startDate)
    const insertDate = convertDate(newValues.insertDate)
    if (newValues.endDate === "") newValues.endDate = Date.now()
    if (newValues.startDate === "") newValues.startDate = new Date().toISOString()
    if (newValues.logo === "") newValues.logo = initialThumbnail.thumbnails
    if (newValues.logo?.includes("https")) newValues.logo = newValues.logo.replace("https:", "").replaceAll(" ", "%20")
    const newDate = new Date(newValues.endDate)
    newDate.setFullYear(newDate.getFullYear() + 1)
    const returnBack = new Date(newDate).toISOString()
    newValues.endDate = returnBack
    if (!newValues.logo || newValues.logo === "") {
      newValues.logo = newValues.fileTitle.split(".")[0] + ".jpg"
    }
    await PostData("/video/videos/add-video", {
      empMediaVideoForm: { ...newValues, startDate, insertDate },
      id: id,
      secondStep: true,
      sendPushNotif: 0,
    }).then((res) => {
      dispatch({ type: 'SET_UPDATE_CUSTOM_FIELDS', payload: 0 })
      dispatch({ type: 'SET_LOADING_BTN', payload: false })
      if (res.data.code === 200) {
        dispatch(
          setAddMediaActions({
            addMediaConstraintsDataNameChange: "author",
            addMediaConstraintsDataValueChange: "",
          })
        );
        dispatch(
          setAddMediaActions({
            addMediaConstraintsDataNameChange: "showthemes",
            addMediaConstraintsDataValueChange: [],
          })
        );
        history.push("/ws-media/medias")

      }
      else {
        dispatch(setErrorsForm(true))
      }
    }).catch(async () => {
      dispatch({ type: 'SET_UPDATE_CUSTOM_FIELDS', payload: 0 })
      message.error(`video upload failed.`)
      dispatch({ type: 'SET_LOADING_BTN', payload: false })
    });
  };



  const editupload = async (id) => {
		dispatch({ type: "SET_LOADING_BTN", payload: true });
		const correctFilesTitles = values.filesVideo.map((file) => {
			const splittedTitle = file.title.split("/");
			const correctTitle = splittedTitle[splittedTitle.length - 1];
			return {
				...file,
				title: correctTitle,
			};
		});
		if (!values.logo || values.logo === "") {
			values.logo = values.fileTitle.split(".")[0] + ".jpg";
		}
		if (values.logo?.includes("https"))
			values.logo = values.logo.replace("https:", "").replaceAll(" ", "%20");
		if (!values.contributor) {
			values.contributor = userData?.id;
		}
		await PostData("/video/videos/edit/" + id, {
			empMediaVideoForm: { ...values, filesVideo: correctFilesTitles },
			id: Number(id),
			sendPushNotif: 0,
		})
			.then((res) => {
				dispatch({ type: "SET_UPDATE_CUSTOM_FIELDS", payload: 0 });
				if (res.data.code === 200) {
					dispatch({ type: "SET_UPDATE_CUSTOM_FIELDS", payload: 0 });
					dispatch({ type: "SET_LOADING_BTN", payload: false });
					history.push("/ws-media/medias");
				} else {
					dispatch(setErrorsForm(true));
					dispatch({ type: "SET_UPDATE_CUSTOM_FIELDS", payload: 0 });
					dispatch({ type: "SET_LOADING_BTN", payload: false });
				}
			})
			.catch((e) => {
				dispatch(setErrorsForm(true));
				dispatch({ type: "SET_UPDATE_CUSTOM_FIELDS", payload: 0 });
				message.error(t("video.edit_failed"));
				dispatch({ type: "SET_LOADING_BTN", payload: false });
			});
	};
  const updateKeywords = (value) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "keyWords",
        addMediaConstraintsDataValueChange: value.join(" "),
      })
    );
  };
  const setThumbnailVideo = async (name) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "logo",
        addMediaConstraintsDataValueChange: name
      })
    );
  };
  const setlocalthumbVideo = async (name) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "logo",
        addMediaConstraintsDataValueChange: name,
      })
    );
  };
  const updateConfValues = async (name, checked) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: name,
        addMediaConstraintsDataValueChange: checked,
      })
    );
  };
  const getVideoDataById = (id, filename, method, cancelToken) => {
    if (method === "edit") {
      if (id) {
        GetData("/video/" + id + (modules?.countries_video ? "?language=" + (i18n.language ?? 'fr') : '')).then((res) => {
          dispatch(setVideoDataLoading(false));
          const fileTitle = res.data?.video ? res.data.video.fileTitle : res.data?.fileTitle ? res.data.fileTitle : filename
          dispatch(
            setAddMediaActions({
              addMediaConstraintsDataNameChange: "fileTitle",
              addMediaConstraintsDataValueChange: fileTitle,
            })
          );   
          if(modules?.countries_video) 
         { 
          dispatch(
            setAddMediaActions({
              addMediaConstraintsDataNameChange: "countriesCodes",
              addMediaConstraintsDataValueChange: Array.from(res.data?.countriesCodes,({id})=>id),
            })
          );
          }
          dispatch(
            setAddMediaActions({
              addMediaConstraintsDataNameChange: "isVisibleOnInternet",
              addMediaConstraintsDataValueChange: res.data.isVisibleOnInternet,
            })
          );
          dispatch(
            setAddMediaActions({
              addMediaConstraintsDataNameChange: "isVisibleOnInternet",
              addMediaConstraintsDataValueChange: res.data.isVisibleOnInternet,
            })
          );
          populateVideoState(id, res, fileTitle, cancelToken);

        }).catch((e) => {
          dispatch(setVideoDataLoading(false));
          console.log("error getting video data", e);
        });
      }
    }
  };
  const getSecondStepUpload = (id, _, cancelToken) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "insertDate",
        addMediaConstraintsDataValueChange: new Date().toISOString(),
      })
    );
    GetData("/video/" + id + "?secondStep=true").then((res) => {
      dispatch(setVideoDataLoading(false));
   populateVideoState(id, res, res?.data?.fileTitle, cancelToken);
   
   
      dispatch(
        setAddMediaActions({
          addMediaConstraintsDataNameChange: "fileTitle",
          addMediaConstraintsDataValueChange: res?.data?.fileTitle,
        })
      );
    }).catch(e => {
      dispatch(setVideoDataLoading(false));
      console.log("error getting video data", e);
    });
  };
  const getVideoUploadedbyurl = async (extenalurl) => {
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "insertDate",
        addMediaConstraintsDataValueChange: new Date().toISOString(),
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "externalLinkVideo",
        addMediaConstraintsDataValueChange: extenalurl,
      })

    );
    await GetData("/player/api/video/preview-link-by-external-link" + "?externalLink=" + extenalurl + "&y=bo",
    ).then((res) => {
      dispatch(setVideoFile(res?.data?.link));
    });
  };

  const getVideoUploaded = async (name) => {
    const noExtension = name?.substring(0, name?.lastIndexOf('.'));
    const formattedString = noExtension?.replace(/[_-]/g, ' ');


    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "insertDate",
        addMediaConstraintsDataValueChange: new Date().toISOString(),
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "title",
        addMediaConstraintsDataValueChange: formattedString?formattedString:name,
      })

    );
    await GetData("/player/api/video/preview-link-by-title/" + name + "?subFolder=&y=bo&clientName=" + window.process.env.CLIENT_NAME,
    ).then((res) => {
      dispatch(setVideoFile(res?.data?.link));
    });
  };
  const getVideoLink = async (id) => {
    if (id) {
      try {
        const res = await axios.get(`${window.process.env.API_PLAYER}/api/video/preview-link/${id}?y=bo`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          }
        }
        );
        dispatch(setVideoLinkLoading(false))
        dispatch(setVideoFile(res?.data?.link));
      } catch (e) {
        dispatch(setVideoLinkLoading(false))
        console.log("error getting video link", e);
      }
    };
  }
  const extractDataVideo = async (link) => {
    try {
      const res = await PostData("/video/video/external-video-info", { urlExternal: link });
      dispatch(setExternalvideoData(res?.data));

      dispatch(
        setAddMediaActions({
          addMediaConstraintsDataNameChange: "externalLinkVideo",
          addMediaConstraintsDataValueChange: link,
        })
      );
      dispatch(
        setAddMediaActions({
          addMediaConstraintsDataNameChange: "insertDate",
          addMediaConstraintsDataValueChange: new Date().toISOString(),
        })
      );
      for (let key in res?.data?.data?.video) {
        dispatch(
          setAddMediaActions({
            addMediaConstraintsDataNameChange: key === "thumbnails" ? "logo" : key,
            addMediaConstraintsDataValueChange: res?.data?.data?.video[key],
          })
        );
      }


      const title = res?.data?.data?.video?.title.normalize("NFD")
        .replace(/[^\w\s]/g, "")
        .replaceAll(" ", "_");;

      if (title) {
        history.push(`/ws-media/pageuploadurl/video/${title}`);
      } else {

        console.error("title is missing from the response.");
      }
    } catch (error) {
      messageCatch();
    }
  };


  let countApiFail = 0
  const getMetaData = async (id, fileName, cancelToken) => {
    try {
      const res = await axios.get("https://" + window.process.env.SERVERLESS_PIPER_ENDPOINT +
        "/api/assets/metadata?subFolder=&video=" +
        fileName,
        { headers: { Authorization: "Bearer " + localStorage.getItem("authToken") }, cancelToken: cancelToken?.token }
      );
  
      let t = res?.data?.streams[0]?.duration ? res?.data?.streams[0]?.duration : res?.data?.format?.duration;
      handleChangeDuration(secondsToHms(t), "duration");
      dispatch(setmetadataVideo(res?.data));
      const metadata = JSON.stringify({ "contentJson": res?.data });
      dispatch(setAddMediaActions({
        addMediaConstraintsDataNameChange: "metaData",
        addMediaConstraintsDataValueChange: metadata
      }));
    } catch (error) {
      countApiFail++;
      if (axios.isCancel(error)) return; 
      if (countApiFail >= 20)    
     { throw new Error("Maximum retry limit reached.")
 }
      else await getMetaData(id, fileName, cancelToken);
    }
  }
// get countries list
  const getCountries = async (lang) => {
    await GetData(`/video-api/video/countries?language=${lang ?? 'fr'}`)
      .then((res) => {
         dispatch(setCountriesList(res?.data?.data));        
      })
      .catch((e) => (() => message.error(`video upload failed.`)));
  };

  const getThemesByChannel = async (link) => {
    await GetData("/channel/channel-by-themes")
      .then((res) => {
        dispatch(setThemes(res?.data));
      })
      .catch((e) => (() => message.error(`video upload failed.`)));
  };
  const getThumbs = async (fileName, count = 3, failedAPI = 0) => {
    await axios.get(
      "https://" + window.process.env.SERVERLESS_PIPER_ENDPOINT +
      "/api/thumbnail?count=1&fileName=" + fileName,
      { headers: { Authorization: "Bearer " + localStorage.getItem("authToken"), 'Client-Name': window.process.env.CLIENT_NAME } }
    )
      .then(async (res) => {
        if (res?.data?.thumbnails === null) {
          if (failedAPI <= 40) {
            await getThumbs(fileName, count, ++failedAPI)
          }
        } else {
          dispatch(setthumbsData(res?.data));
          dispatch(setThumbnailsPathExists(true))
        }
      })
      .catch(async () => {
        if (failedAPI <= 40) {
          await getThumbs(fileName, count, ++failedAPI)
        } else {
          messageCatch()
        }
      });

  };

  // receive path of thumbs as parameter , verify if thumbnail exists or not
  const checkThumbnail = async (path) => {
    return await axios.get(path)
  }
  const getThumbsTime = async (fileName, time) => {
 try {  dispatch(setLoading(true));
    await axios
      .get("https://" + window.process.env.SERVERLESS_PIPER_ENDPOINT +
        "/api/thumbnail?clientName=" + window.process.env.CLIENT_NAME + "&fileName="
        + fileName +
        "&timecode=" + timeForThumbnail(time),
        { headers: { Authorization: "Bearer " + localStorage.getItem("authToken") } }
      )
      .then((res) => {
        const bucketUrlSplitted = window.process.env.BUCKET_URL_THUMBNAIL.split("/");
        const bucketUrl = bucketUrlSplitted.slice(0, bucketUrlSplitted.length - 2).join("/");
        setThumbnailVideo(bucketUrl + "/" + res?.data?.thumbnails[0]);
        dispatch(setscreenthumbsData(res?.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        messageCatchCurrupted()
        dispatch(setLoading(false))
      });}
       catch(error){
        messageCatchCurrupted()
      }
  };
  const getStoryboard = async () => {
    await GetData("/programDisplay/display-client")
      .then((res) => {
        dispatch(setStoryboard(res?.data?.url_display));
      })
      .catch(() => messageCatch());
  };
  const getContributors = async () => {
    await GetData("/video/videos/contributors/list")
      .then((res) => {
        dispatch(setContributorData(res?.data));
      })
      .catch(() => messageCatch());
  };
  const getPendingVideos = async (page = 1, pageSize = 10) => {
    const start = pageSize * (page - 1)
    await GetData(`/dataTable/videos/onhold?columns%5B0%5D%5Borderable%5D=true&draw=1&length=${pageSize}&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=desc&start=${start}`)
      .then((res) => {
        dispatch(setPendingVideoData(res?.data?.data));
        dispatch({ type: "GET_VIDEOS_RECORD", payload: res?.data?.recordsFiltered })
      })
      .catch(() => messageCatch());
  };
  const previewVideo = async (id) => {
    await GetData("/video/video/preview-link/" + id + "?y=bo")
      .then((res) => {
        dispatch(setVisible(true));
        dispatch(setPreviewLink(res?.data?.link));
      })
      .catch(() => messageCatch());
  };
  const deletePendingVideo = async (id) => {
    dispatch(setVisibleDelete(false));
    dispatch(setLoadingDelete(true));

    await DeleteData("/multiDelete/videos/" + id).then((res) => {
      if (res.data.code === 200) {
        dispatch(setLoadingDelete(false));
        message.success(`video supprimé avec succès`);
        dispatch(
          setPendingVideoData(pendingVideo.filter((row) => row.id !== id))
        );
      }
      else {
        dispatch(setLoadingDelete(false));
        message.error(`${t('serverError')}`)
      }
    }).catch(err => {
      dispatch(setLoadingDelete(false))
      message.error(`${t('serverError')}`)

    })

  };
  const deletePendingMultipleVideos = async (videos) => {
    dispatch(setVisibleDeleteMultiple(false));
    dispatch(setLoadingDelete(true));

    await DeleteData("/multiDelete/videos/" + videos).then((res) => {
      if (res.data.code === 200) {
        dispatch(setLoadingDelete(false));
        const videosToDeleteSet = new Set(videos);
        dispatch(
          setPendingVideoData(
            pendingVideo.filter((v) => {
              return !videosToDeleteSet.has(v.id);
            })
          )
        );

        message.success(`Les vidéos ont été supprimés avec succès`);
      }
    }).catch(err => {
      dispatch(setLoadingDelete(false));
      dispatch(setLoading(false));
      message.error(`${t('serverError')}`)
    })
  };
  const showModal = () => {
    setisModalVisible(true);
  };
  const handleOk = () => {
    setisModalVisible(false);
  };
  const onFinishAddMedia = async (id) => {
		await postupload(id);
	};
  const onFinishAddMediaurl = async (id) => {
    await postuploadurl(id);

  };

  const onFinishAddMediaUpload = async (id, step) => {
    await postupload(id, step); //edit video
  };
  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      img.onerror = () => {
        callback(false);
      };
    }
  }
  const initStateVideoInfo = (filename) => {
    dispatch(setAddMediaActions({
      addMediaConstraintsDataNameChange: "title",
      addMediaConstraintsDataValueChange: filename ? filename : "",
    })
    );
    dispatch(setAddMediaActions({
      addMediaConstraintsDataNameChange: "fileTitle",
      addMediaConstraintsDataValueChange: "",
    })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "description",
        addMediaConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "transcription",
        addMediaConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "logo",
        addMediaConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "passwordInternet",
        addMediaConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "secure",
        addMediaConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "allow_share",
        addMediaConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "isPrivate",
        addMediaConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "offline",
        addMediaConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "isSecuredOnInternet",
        addMediaConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "is_accessible",
        addMediaConstraintsDataValueChange: true,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "downloadable",
        addMediaConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "keyWords",
        addMediaConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "themes",
        addMediaConstraintsDataValueChange: [],
      })
    );
  }
  const convertDate = (date) => {
    date = moment(date);
    const newDate = date.format('YY-MM-DD 00:00:00')
    return newDate
  }
  const deleteAttachedFile = async (fileName, id) => {
    DeleteData(`/video/video/delete/attached-file/${id}`, { fileName })
  }
  return {
    extractDataVideo,
    showModal,
    timer,
    onFinishAddMediaUpload,
    uploadThumb,
    getSubsGroups,
    getStoryboard,
    handleOk,
    onFinishAddMedia,
    onFinishAddMediaurl,
    previewVideo,
    getPendingVideos,
    handleChangeInputs,
    updateSubsGroups,
    postupload,
    postuploadurl,
    editupload,
    getVideoUploaded,
    getContributors,
    updatecontributor,
    getSecondStepUpload,
    getVideoDataById,
    updateThemes,
    updateFilesVideos,
    handleChangeDate,
    getThemesByChannel,
    isModalVisible,
    updateConfValues,
    getVideoLink,
    getThumbs,
    updateKeywords,
    deletePendingVideo,
    deletePendingMultipleVideos,
    formatBytes,
    getThumbsTime,
    checkThumbnail,
    setThumbnailVideo,
    setlocalthumbVideo,
    getMetaData,
    secondsToHms,
    getThumbsthemes,
    checkIfImageExists,
    initStateVideoInfo,
    deleteAttachedFile,
    getVideoUploadedbyurl,
    populateVideoState,
    updateCountries,
    getCountries

  };
};
