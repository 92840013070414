import React, { useEffect, useState, useRef } from "react";
import "../../../index.css";
import FileUpload from "./FileUpload";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Hooks from "../../utils/hooks";
import { CategorieSelect, LanguageSelect } from "./Select";
import Toggle_add_Podcast from "./Toggle_add_Podcast";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@empreinte/components.checkbox";
import {
	cleanPodcastItems,
	cleanPodcastVideo,
	GetSelectChoices,
	handleUploadCancel,
	getTusId,
	PodcastConditionAdd,
	cleanThumbs,
	cleanPodcastitems,
	cleanPodcastThemes,
} from "../../utils/action";
import "../../Pages/podcast.scss";
import { useHistory } from "react-router-dom";
import { getUserData } from "../../../userData/userDataActions";
import PodcastClass from "./const";
import { Dialog, DialogContent } from "@mui/material";
import { ClipLoader } from "@empreinte/components.clip-loader";
import { Header } from "@empreinte/components.header";
import { InputText } from "@empreinte/components.input-text";
import { TextArea } from "@empreinte/components.text-area";
import { DatePickers } from "@empreinte/components.date-pickers";
import { SelectRadio } from "@empreinte/components.select-radio";
import { InputTags } from "@empreinte/components.input-tags";

const AddPodcastMain = () => {
	const { HandleAddPodcast } = Hooks();
	const thumbNail = useSelector((state) => state.ReducerGetPodcastData.AddPodcastItems.Vignette);
	const values = useSelector((state) => state.ReducerGetPodcastData.AddPodcast);
	let date = new Date();
	let year = date.getFullYear();
	let month = date.getMonth();
	let day = date.getDate();
	let dDate = new Date(year + 10, month, day);
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [valueStart, setValueStart] = useState(new Date());
	const [valueEnd, setValueEnd] = useState(dDate);
	const [title, setTitle] = useState(values?.Title);
	const [isValid, setisValid] = useState(false);
	const Id = useSelector((state) => state.ReducerGetPodcastData.tus_id);

	const redirect = useSelector((state) => state.ReducerGetPodcastData.on_redirect);
	const modules = useSelector((state) => state.ReducerUserData.modules);
	const isSuperAdmin = useSelector((state) => state.ReducerUserData.isSuperAdmin);
	const postuploadRedirect = useSelector((state) => state.ReducerGetPodcastData.redirectPostUpload);
	const disableBtn = useSelector(
		(state) => state.ReducerGetPodcastData.PodcastCondition.disableSubmitForm
	);
	const [loadingModalPostUpload, setloadingModalPostUpload] = useState(false);
	const editInputRef = useRef(null);
	const [tags, setTags] = useState([]);
	const [disabledBtn, setDisabledBtn] = useState(false);

	useEffect(() => {
		if (title?.length) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [title, disabledBtn]);

	useEffect(() => {
		if (valueEnd < valueStart) {
			setisValid(false);
		} else {
			setisValid(true);
		}
	}, [valueStart, valueEnd]);

	useEffect(() => {
		let id = JSON.parse(PodcastClass.getId())?.podcastId;
		dispatch(getTusId(id));
	}, []);

	useEffect(() => {
		dispatch(GetSelectChoices());
	}, []);

	const handleCancel = () => {
		if (Id) {
			dispatch(handleUploadCancel(Id));
			dispatch(cleanPodcastItems());
			dispatch(cleanPodcastVideo());
		} else {
			dispatch(cleanPodcastItems());
			dispatch(cleanPodcastVideo());
			history.push("/ws-media/podcasts");
		}
	};

	let convertBoolean = (value) => {
		if (value && typeof value === "string") {
			if (value.toLowerCase() === "true") return true;
			if (value.toLowerCase() === "false") return false;
		}
		return value;
	};

	useEffect(() => {
		if (redirect) history.push("/ws-media/podcasts");
	}, [redirect]);

	useEffect(() => {
		if (title?.length > 0) {
			setisValid(true);
		} else {
			setisValid(false);
		}
	}, [title]);

	useEffect(() => {
		if (postuploadRedirect) {
			setloadingModalPostUpload(false);
			history.push("/ws-media/podcasts");
		}
	}, [postuploadRedirect]);

	const handleEditInputConfirm = (e) => {
		e.preventDefault();
		const tagValue = editInputRef.current.value.trim();
		if (tags?.includes(tagValue) || tagValue === " " || tagValue === "") return;
		const newTags = [...tags, tagValue];
		setTags(newTags);
		const tagsString = newTags.join(",");
		dispatch(
			PodcastConditionAdd({
				addPodcastDataNameChange: "Keywords",
				addPodcastDataValueChange: tagsString,
			})
		);
		editInputRef.current.value = "";
	};

	const handleTagDelete = (value) => {
		const newtags = tags.filter((val) => val !== value);
		setTags(newtags);
		dispatch(
			PodcastConditionAdd({
				addPodcastDataNameChange: "Keywords",
				addPodcastDataValueChange: newtags,
			})
		);
	};

	const handleChangeForm = (e) => {
		dispatch(
			PodcastConditionAdd({
				addPodcastDataNameChange: e.target.name,
				addPodcastDataValueChange: e.target.value,
			})
		);
	};

	const handleChangeText = (e) => {
		setTitle(e.target.value);
		dispatch(
			PodcastConditionAdd({
				addPodcastDataNameChange: e.target.name,
				addPodcastDataValueChange: e.target.value,
			})
		);
	};

	const handleChecksForm = (e) => {
		dispatch(
			PodcastConditionAdd({
				addPodcastDataNameChange: e.target.name,
				addPodcastDataValueChange: convertBoolean(e.target.checked),
			})
		);
	};

	const handleRadioFormExplicit = (e) => {
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: e.target.name,
        addPodcastDataValueChange: convertBoolean(e.target.value),
      })
    );
  };
  const handleRadioFormDownloadable = (e) => {
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: e.target.name,
        addPodcastDataValueChange: convertBoolean(e.target.value),
      })
    );
  };
  const handleRadioFormOffline = (e) => {
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: e.target.name,
        addPodcastDataValueChange: convertBoolean(e.target.value),
      })
    );
  };

	const handleStartDateChange = (newStartDate) => {
		setValueStart(newStartDate);
		dispatch(
			PodcastConditionAdd({
				addPodcastDataNameChange: "StartDate",
				addPodcastDataValueChange: newStartDate,
			})
		);
	};

	const handleEndDateChange = (newEndDate) => {
		setValueEnd(newEndDate);
		dispatch(
			PodcastConditionAdd({
				addPodcastDataNameChange: "EndDate",
				addPodcastDataValueChange: newEndDate,
			})
		);
	};

	return (
		<>
			<Header
				title={t("Podcast.add-edit.Podcast Information")}
				onMainPage={false}
				hasPermission={true}
				secondBtnTitle={t("Podcast.add-edit.cancel")}
				thirdBtnTitle={t("Podcast.add-edit.Add")}
				CancelBtnAction={handleCancel}
				arrowBackAction={() => history.goBack()}
				addBtnAction={() => {
					dispatch(HandleAddPodcast);
					dispatch(cleanThumbs());
					dispatch(cleanPodcastitems());
					dispatch(cleanPodcastThemes());
					history.push("/ws-media/podcasts");
				}}
				isDisabled={disabledBtn}
			/>
			<div className="add_podcast_container">
				<div className="upload_section">
					<div className="file_upload_container">
						<FileUpload fileURL={thumbNail} content={t("Podcast.add-edit.CHOOSE A THUMB")} />
					</div>
					{postuploadRedirect ? null : (
						<Dialog
							open={loadingModalPostUpload}
							onClose={loadingModalPostUpload}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogContent sx={{ height: "50%" }}>
								<ClipLoader color="inherit" thickness={1.6} style={{ marginTop: "6px" }} />
							</DialogContent>
						</Dialog>
					)}
					<span className="maximum_upload_size">
						{t("Podcast.add-edit.The maximum thumbnail size is 2MB.")}
					</span>
					<span className="acceptedDimenssion">
						<div> {t("Dimensions accepted:")}</div>
						<span>480p (SD) : 720 x 480</span>
						<span>720p (HD) : 1280 x 720</span>
						<span>1080p (HD) : 1920 x 1080</span>
					</span>
				</div>
				<div className="upload_file_inputs">
					<Box
						sx={{
							width: "100%",
							maxWidth: "100%",
							paddingBottom: "20px",
						}}
					>
						<InputText
							value={title}
							type="text"
							name="Title"
							style={{ width: "100%" }}
							fullWidth
							required
							labelInput={t("Podcast.Title")}
							placeholder={t("Podcast.Title")}
							handleChange={handleChangeText}
						/>
					</Box>
					<Box
						sx={{
							width: "100%",
							maxWidth: "100%",
							paddingBottom: "30px",
						}}
					>
						<TextArea
							value={values?.Description}
							className="podcast-text-area"
							rows={15}
							maxRows={25}
							placeholder={t("Podcast.Description")}
							labelInput={t("Podcast.Description")}
							id="fullWidth"
							name="Description"
							handleChange={handleChangeForm}
						/>
					</Box>
				</div>
				<br />

				{(modules?.podcast_form_date || isSuperAdmin) && (
					<div has-permission={"podcast_form_date"} className="podcast-date-picker-edit">
						<DatePickers
							className="podcast-date-picker1"
							inputFormat="DD/MM/YYYY"
							labelTop={t("Podcast.add-edit.Start date")}
							openTo="year"
							views={["year", "month", "day"]}
							value={valueStart}
							maxDate={new Date(valueEnd)}
							onChange={handleStartDateChange}
							renderInput={(params) => <TextField {...params} name="valueStart" />}
						/>
						<DatePickers
							className="podcast-date-picker2"
							inputFormat="DD/MM/YYYY"
							labelTop={t("Podcast.add-edit.End Date")}
							openTo="year"
							views={["year", "month", "day"]}
							value={valueEnd}
							minDate={new Date(valueStart)}							
							onChange={handleEndDateChange}
							renderInput={(params) => <TextField {...params} name="EndDate" />}
						/>
					</div>
				)}
				<div className="new-fields-style-podcast" style={{ paddingTop: "20px" }}>
					<InputText
						style={{ width: "100%" }}
						fullWidth
						labelInput={t("Podcast.add-edit.Editor")}
						handleChange={handleChangeForm}
						value={values?.Editor}
						name="Editor"
						placeholder={`${t("Podcast.add-edit.Editor")}`}
					/>

					<InputText
						style={{ width: "100%" }}
						fullWidth
						labelInput={t("Podcast.add-edit.ArtistAuthor")}
						handleChange={handleChangeForm}
						value={values?.Author}
						name="Author"
						placeholder={`${t("Podcast.add-edit.ArtistAuthor")}`}
					/>
				</div>
				<div className="new-fields-style-podcast">
					<InputText
						style={{ width: "100%" }}
						fullWidth
						labelInput={t("Podcast.add-edit.Copyright")}
						handleChange={handleChangeForm}
						value={values?.Copyright}
						name="Copyright"
						placeholder={`${t("Podcast.add-edit.Copyright")}`}
					/>
					<InputText
						style={{ width: "100%" }}
						fullWidth
						labelInput={t("Podcast.add-edit.EncodedBy")}
						handleChange={handleChangeForm}
						value={values?.Encoded_By}
						name="Encoded_By"
						placeholder={`${t("Podcast.add-edit.EncodedBy")}`}
					/>
				</div>
				<InputTags
					label={t("video.keywords")}
					tags={tags || []}
					className="input-tags-podcast"
					handleOnSubmit={handleEditInputConfirm}
					onBlur={handleEditInputConfirm}
					handleDelete={handleTagDelete}
					tagRef={editInputRef}
					placeholder={t("Podcast.add-edit.Keywords")}
				/>

				<div className="select_options">
					{(modules?.podcast_form_langue || isSuperAdmin) && (
						<div has-permission="podcast_form_langue">
							<LanguageSelect />
						</div>
					)}
					<div className="contenu_container_Edit">
						{(modules?.podcast_form_is_accessible || isSuperAdmin) && (
							<div has-permission="podcast_form_is_accessible" className="checkboxes">
								<Checkbox
									name="IsAccessible"
									label={t("Podcast.add-edit.Make the podcast visible on the internet")}
									checked={values?.IsAccessible ? values?.IsAccessible : true}
									handleChange={handleChecksForm}
								/>
							</div>
						)}
						{(modules?.podcast_form_explicit || isSuperAdmin) && (
							<div has-permission="podcast_form_explicit" className="contenu_container">
								<h2 style={{ marginTop: "7px" }}>
									{t("Podcast.add-edit.Does the podcast contain explicit content?")}
								</h2>
								<div className="radio_btn_container">
									<SelectRadio
										name="Explicite"
										radioElementClass="radioButtons"
										value={values?.Explicite}
										onChange={handleRadioFormExplicit}
										datalist={[
											{
												label: t("Podcast.add-edit.No"),
												value: "false",
											},
											{
												label: t("Podcast.add-edit.Yes"),
												value: "true",
											},
										]}
									/>
								</div>
							</div>
						)}

					</div>
					{(modules?.podcast_post_download || isSuperAdmin) && (
					<div
						has-permission="podcast_post_download"
						className="contenu_container"
					>
						<h2 style={{ marginTop: "7px" }}>
						{t(
							"Podcast.add-edit.podcast_downloadable"
						)}
						</h2>
						<div className="radio_btn_container">
						<SelectRadio
							name="IsDownloadable"
							radioElementClass="radioButtons"
							value={values?.IsDownloadable}
							onChange={handleRadioFormDownloadable}
							datalist={[
							{
								label: t("Podcast.add-edit.No"),
								value: "false",
							},
							{
								label: t("Podcast.add-edit.Yes"),
								value: "true",
							},
							]}
						/>
						</div>
					</div>
					)}
					{(modules?.podcast_offline || isSuperAdmin) && (
          <div
            has-permission="podcast_offline"
            className="contenu_container"
              >
                <h2 style={{ marginTop: "7px" }}>
                  {t(
                    "Podcast.add-edit.podcast_offline"
                  )}
                </h2>
                <div className="radio_btn_container">
                  <SelectRadio
                    name="IsOffline"
                    radioElementClass="radioButtons"
                    value={values?.IsOffline}
                    onChange={handleRadioFormOffline}
                    datalist={[
                      {
                        label: t("Podcast.add-edit.No"),
                        value: "false",
                      },
                      {
                        label: t("Podcast.add-edit.Yes"),
                        value: "true",
                      },
                    ]}
                  />
                </div>
              </div>
            )}
					{(modules?.podcast_form_category || isSuperAdmin) && (
						<div has-permission="podcast_form_category">
							<CategorieSelect />
						</div>
					)}
             
				</div>
				<div className="tgl_container">
					<Toggle_add_Podcast />
				</div>
			</div>
		</>
	);
};
export default AddPodcastMain;
