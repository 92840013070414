import React, { useEffect, useState, useLayoutEffect } from "react";
import "./Dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "./Components/loadingScreen";
import Notfound from "./Components/Notfound";
import i18next from "i18next";
import {
  getModules,
  getDirectsPlannedNumber,
  getVideoNumberForStat,
  getPlayLists,
  getPodcasts,
    getVideos,
} from "./Redux/actions";
import { useLocation } from "react-router-dom";
import Header from "./Components/Header";
import Stat from "./Components/Stat";
import GridSection from "./Components/GridSection";
import { PodcastGraphQlFetchData } from "../Podcast/graph-ql-podcast/GraphQlFetchData";

const checkModules = (e) => {
  if (e) {
    return Object.values(e).every(u => u.authorized);
  } else {
    return false;
  }
};

const Dashboard = () => {
  const { GETPODCASTLIST } = PodcastGraphQlFetchData();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const obj = new URLSearchParams(search);
  const { GlobalLoading, Authorized, UserData, allModules } = useSelector((state) => ({
    ...state.Dashboard,
  }));
  const { totalRecentVideoNumber, RecentVideoData } = useSelector((state) => ({
    ...state.RecentVideos,
  }));
  const { totalRecentPlaylistNumber, RecentPlaylistData } = useSelector(
    (state) => ({
      ...state.RecentPlaylist,
    })
  );
  const { totalRecentPodcastNumber, RecentPodcastData } = useSelector(
    (state) => ({
      ...state.RecentPodcast,
    })
  );
  const { totalRecentLivesNumber, RecentLivesData } = useSelector((state) => ({
    ...state.RecentLives,
  }));
  const podcastData = useSelector((state) => state.ReducerGetPodcastData.podcastData);


  const FilterList = useSelector(
    (state) => state.ReducerGetPodcastData.FilterList
  );

  
  const [Filter, setFilterValue] = useState(FilterList);



  useLayoutEffect(() => {
    dispatch(getModules());
  }, []);
  
  useEffect(() => {
    if (Authorized ) {
      if(checkModules(allModules.playlist_module)){
        dispatch(getPlayLists(UserData.id));
      }
      if(checkModules(allModules.lives_module)){
        dispatch(getDirectsPlannedNumber(UserData.id));
      }
      if(checkModules(allModules.podcast_module)){
        dispatch(getPodcasts(UserData.id));
        GETPODCASTLIST({
          variables: {
						where: { isArchived: Filter==="trash"?true:false },
						...(Filter!=="trash" && {isOffline:Filter==="offline"?true:false}),
            pagination: { limit: 5, offset:0},
            orderBy: { direction: "DESC" },
          },
        });
      }
      dispatch(getVideoNumberForStat());
      dispatch(getVideos());

    }
  }, [Authorized,allModules]);

  useEffect(() => {
    const lang = obj.get("lang");
    if (lang === "en" || lang === "fr") {
      i18next.changeLanguage(lang);
    }
  }, [obj.get("lang")]);

    const stats = [
    {
      num: totalRecentPodcastNumber,
      name: "Podcast",
      url: "podcast/list",
      img: "/podcast.svg",
      color: "blue",
      textColor: "darkblue",
      data: podcastData,
    },
    {
      num: totalRecentVideoNumber,
      name: "Vidéos",
      url: "medias/videos",
      img: "/video-icon.svg",
      color: "orange",
      textColor: "darkorange",
      data: RecentVideoData,
    },
    {
      num: totalRecentPlaylistNumber,
      name: "Playlist",
      url: "playlist/list/simple",
      img: "/playlist.svg",
      color: "rose",
      textColor: "darkrose",
      data: RecentPlaylistData,
    },
    {
      num: totalRecentLivesNumber,
      name: "Direct",
      url: "lives/list",
      img: "/live-icon.svg",
      color: "white",
      textColor: "darkwhite",
      data: RecentLivesData,
    },
  ];
  const filteredStats = stats.filter((stat) => stat?.data?.length >= 1);
  const renderClasses = ["first", "second", "third"];

  return (
    <>
      {GlobalLoading ? (
        <LoadingScreen />
      ) : Authorized ? (
        <div className="DaashBoardContainer">
          <Header />
          <div className="grid">
            {filteredStats.map((e, i) => (
              <Stat
                key={i}
                color={e.color}
                img={e.img}
                desc={e.name}
                num={e.num}
                textColor={e.textColor}
              />
            ))}
          </div>
          <div
            className={`kk ${
              filteredStats.length === 4
                ? "grid2"
                : filteredStats.length === 3
                ? "grid3"
                : filteredStats.length === 2
                ? "grid4"
                : ""
            }`}
          >
            {filteredStats.map((e, i) => (
              <GridSection
                key={i}
                s={renderClasses[i]}
                title={e.name}
                data={e.data}
                name={e.name}
                check={filteredStats.length}
                url={e.url}
              />
            ))}
          </div>
        </div>
      ) : (
        <Notfound />
      )}
    </>
  );
};
export default Dashboard;
