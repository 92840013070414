export function setAddMediaActions(e) {
    return { type: "SET_AddMEDIAActions", payload: e };
}

export function setExternalvideoData(e) {
    return { type: "SET_ExternalvideoData", payload: e };
}
export function setThumbnailsPathExists(e) {
    return { type: "SET_PathThumbnailExist", payload: e };
}

export function setErrorsForm(e) {

    return { type: "SET_ErrorForm", payload: e };
}

export function setCatchError(e) {

    return { type: "SET_catchError", payload: e };
}

export function setIdVideoAdded(e) {
    return { type: "SET_ID_VIDEO_ADDED", payload: e };
}

export function setIdVideo(e) {

    return { type: "SET_ExternalvideoData", payload: e };
}
export function setLoading(e) {
    return { type: "SET_loading", payload: e };
}

export function setmetadataVideo(e) {

    return { type: "SET_MetadataVideo", payload: e };
}

export function setShowthemes(e) {
    return { type: "SET_themesShow", payload: e };
}

export function setthumbsthemes(e) {
    return { type: "SET_thumbsthemes", payload: e };
}

export function setStoryboard(e) {
    return { type: "SET_StoryboardUrl", payload: e };
}

export function setSubscGroups(e) {
    return { type: "SET_SubsGroups", payload: e };
}

export function setVideoFile(e) {
    return { type: "SET_VideoFile", payload: e };
}

export function setPendingVideoData(e) {
    return { type: "SET_PendingVideoData", payload: e };
}
export function setContributorData(e) {
    return { type: "SET_ContributorData", payload: e };
}
export function setthumbsData(e) {
    return { type: "SET_thumbs", payload: e };
}

export function setscreenthumbsData(e) {
    return { type: "SET_screenthumbs", payload: e };
}

export function setVisible(e) {
    return { type: "SET_ModalVisible", payload: e };
}
export function setVisibleDelete(e) {
    return { type: "SET_deleteModalVisible", payload: e };
}
export function setVisibleDeleteMultiple(e) {
    return { type: "SET_deleteMultipleModalVisible", payload: e };
}

export function setvideoname(e) {
    return { type: "SET_videoname", payload: e };
}
export function setPreviewLink(e) {
    return { type: "SET_PreviewLink", payload: e };
}
export function setThemes(e) {
    return { type: "SET_Themes", payload: e };
}
export function setCountriesList(e) {
    return { type: "SET_Countries", payload: e };
}
export function setUploadstate(e) {
    return { type: "SET_UPLOADSTATE", payload: e };
}


export function Automaticadd_trigger(data) {
    return { type: "SET_AUTOMATIC_ADD", payload: data }
}
export function setLoadingDelete(e) {
    return { type: "SET_Loading_Delete", payload: e }
}
export function setTabClicked(e) {
    return { type: "SET_CLICKED_TAB", payload: e }
}
export function resetState() {
    return { type: "RESET_STATE" }
}
export function setVideoDataLoading(e) {
    return { type: "SET_VIDEO_DATA_LOADING", payload: e }
}
export function setVideoLinkLoading(e) {
    return { type: "SET_VIDEO_LINK_LOADING", payload: e }
}