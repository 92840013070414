import gql from "graphql-tag";

export const GET_ALL_PODCAST = gql`
  mutation (
    $where: PodcastWhereInput
    $pagination: Pagination
    $orderBy: PodcastOrder
  ) {
    podcasts(where: $where, pagination: $pagination, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          id
          Title
          Description
          Vignette
          Lang
          Explicite
          IsReady
          StartDate
          EndDate
          IsAccessible
          CreatedAt
          UpdatedAt
          Author
          Editor
          Encoded_By
          CreatedBy
          Copyright
          Keywords
          IsArchived
          ArchivedDate
          IsDownloadable
          IsOffline
          Themes {
            ThemeId
            ThemeTitle
          }
          PodcastItems{
            Id
            PodcastId
            Title
            Audio
            Vignette
            Video
            Type
            Size
            Duration
            Description
        }
        }
      }
    }
  }
`;
export const GET_PODCAST_INFO = gql`
  mutation ($where: PodcastWhereInput) {
    podcasts(where: $where) {
      edges {
        node {
          id
          Title
          Description
          Vignette
          Lang
          Explicite
          IsReady
          StartDate
          EndDate
          IsAccessible
          CreatedAt
          CreatedBy
          UpdatedAt
          Author
          Editor
          Encoded_By
          Copyright
          Keywords
          IsArchived
          ArchivedDate
          IsDownloadable
          IsOffline
          Themes {
            ThemeId
            ThemeTitle
          }
          PodcastItems{
            Id
            PodcastId
            Title
            Audio
            Vignette
            Video
            Type
            Size
            Duration
            Description
        }
        }
      }
    }
  }
`;

export const UPDATE_PODCAST = gql`
  mutation ($input: PodcastInputUpdate!) {
    updatePodcast(input: $input) {
      code
      message
    }
  }
`;

export const ADD_PODCAST = gql`
  mutation ($input: PodcastInputCreate!) {
    createPodcast(input: $input) {
      ID
      code
      message
    }
  }
`;
export const DELETE_PODCAST = gql`
  mutation ($id: Int) {
    deletePodcast(id: $id) {
      code
      message
    }
  }
`;

export const MULTI_DELETE = gql`
  mutation ($ids: [Int]) {
    DeletePodcasts(ids: $ids) {
      code
      message
    }
  }
`;

export const ARCHIVE_PODCASTS = gql`
  mutation ($ids: [Int]) {
    ArchivePodcasts(ids: $ids) {
      code
      message
    }
  }
`;

export const UNARCHIVE_PODCASTS = gql`
  mutation ($ids: [Int]) {
    UnarchivePodcasts(ids: $ids) {
      code
      message
    }
  }
`;

export const URL_EXPORT_PODCAST = gql`
  mutation ($where: PodcastWhereInput, $pagination: Pagination) {
    podcasts(where: $where, pagination: $pagination) {
      edges {
        node {
          id
          URLExportChannel
          URLExportPodcast
        }
      }
    }
  }
`;
