import { useState, useEffect } from "react";
import {
	Row,
	Col,
	Input,
	Spin,
	Divider,
	Button,
	Radio,
	Card,
	Form,
	DatePicker,
	Select,
	Upload,
	Checkbox,
	message,
	Modal,
	Tabs,
} from "antd";
import { UploadOutlined, TagOutlined, EditOutlined, SettingOutlined } from "@ant-design/icons";
import "../addMedia.scss";
import "./formeditvideo.css";
import { AddHooks } from "../utils/hooks";
import { useAppSelector } from "../../redux/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { setErrorsForm, setAddMediaActions } from "../store/addMediaActions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AdditionalInfo } from "./additionalInfo";
import { CustomFields } from "customfields";
import i18next from "i18next";
import { EventHandler } from "../../Medias/utils/event";
import defaultThumb from "../../assets/vignette.png";

export const Formeditvideo = ({ add }) => {
	const { id, filename } = useParams();
	const updatedCustomFields = useSelector((state) => state.addMediaReducer.update);
	const dispatch = useDispatch();
	const {
		handleChangeInputs,
		secondsToHms,
		setlocalthumbVideo,
		getSubsGroups,
		getThumbsthemes,
		formatBytes,
		getThumbsTime,
		getThemesByChannel,
		getCountries,
		getContributors,
		handleChangeDate,
		updateSubsGroups,
		updateThemes,
		updateConfValues,
	} = AddHooks();
	const { Option } = Select;
	const { t , i18n } = useTranslation();
	const [value, setValue] = useState("");
	const [themeoptvalues, setthemeoptvalues] = useState([]);
	const [passwordVisibleiText, setPasswordVisibleText] = useState(false);
	const [, setScreenthumbs] = useState(false);
	const [enddate, setEndDate] = useState();
	const [selectedTheme, setselectedTheme] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [optionThemes, setOptionThemes] = useState([]);
	const [visible, setvisible] = useState(false);

	const [showUploadFile, setShowUploadFile] = useState(false);
	const [handleErrorUploadMessage, setHandlErrorUploadMessage] = useState(false);

	const modules = useSelector((state) => state.ReducerUserData.modules);
	const isSuperAdmin = useSelector((state) => state.ReducerUserData.isSuperAdmin);
	const values = useAppSelector((state) => state.addMediaReducer.AddMediaConstraintsData);
	const showthemes = useAppSelector((state) => state.addMediaReducer.showthemes);
	const subsGroups = useAppSelector((state) => state.addMediaReducer.subsGroups);
	const CheckboxConf = [
		// { label: `${t("video.Video_securise")}`, name: 'secure' },
		{
			label: `${t("video.downloadable")}`,
			name: "downloadable",
			permissionlabel: "media_video_downloadable_vid_form_no_api",
			permission: modules.media_video_downloadable_vid_form_no_api,
		},
		{
			label: `${t("video.allow_share")}`,
			name: "allow_share",
			permissionlabel: "media_video_shared_vid_form_no_api",
			permission: modules.media_video_shared_vid_form_no_api,
		},
		{
			label: `${t("video.offline")}`,
			name: "offline",
			permissionlabel: "media_video_post_toggle",
			permission: modules.media_video_post_toggle,
		},
		{
			label: `${t("video.visible")}`,
			name: "isVisibleOnInternet",
			permissionlabel: "media_video_post_acceessible",
			permission: modules.media_video_post_acceessible,
		},
		{
			label: `${t("video.Home_Video")}`,
			name: "mainPage",
			permissionlabel: "media_home_post_disable_push_video",
			permission: modules.media_home_post_disable_push_video,
		},
		{
			label: `${t("video.private_video")}`,
			name: "isPrivate",
			permissionlabel: "media_video_private",
			permission: modules.media_video_private,
		},
		{
			label: `${t("video.isSecuredOnInternet")}`,
			name: "isSecuredOnInternet",
			permissionlabel: "media_video_password_form_no_api",
			permission: modules.media_video_password_form_no_api,
		},
	];

	const [tags, setTags] = useState([]);
	const themesList = useAppSelector((state) => state.addMediaReducer.themes);
	const link = useAppSelector((state) => state.addMediaReducer.linkVideo);
	const thumbspertheme = useAppSelector((state) => state.addMediaReducer.thumbstheme);
	const metadata = useAppSelector((state) => state.addMediaReducer.metaData);
	const timevideoscreenthumbs = useAppSelector((state) => state.MediaReducer.timevideo);
	const errorFormSubmit = useAppSelector((state) => state.addMediaReducer.errorForm);
	const customFieldsAPI = useAppSelector((state) => state.MediaReducer.customFieldsAPI);

	const loading = useAppSelector((state) => state.addMediaReducer.loading);

	const [logo, setLogo] = useState();

	const location = useLocation();
	const queryParam = new URLSearchParams(location.search);
	const iframeKey = useAppSelector((state) => state.MediaReducer.iframeKey);
	const [lang, setLang] = useState(
		queryParam.get("lang") ||
			(localStorage.getItem("lang") !== "null" && localStorage.getItem("lang")) ||
			"fr"
	);
	const color = queryParam.get("color");
	const [key, setKey] = useState(false);
	const [isvalid, setvalid] = useState(true);

	const { EventListener } = EventHandler();

	useEffect(() => {
		if (values && values.logo) {
			var img = new Image();

			img.onload = function () {
				setvalid(true);
				setLogo(values.logo);
			};
			img.onerror = function () {
				setvalid(false);
			};
			// vérifier la source de l'image
			img.src = window.process.env.BUCKET_URL_THUMBNAIL + values.logo + "?v=";
		}
	}, [values]);

  useEffect(() => {
    EventListener();
    window.parent.postMessage({ info: "edit_video", value: "edit" }, "*");
    window.addEventListener("message", function (event) {
      if (event.data?.info === "updateLang") {
        setLang(event.data.lang)
        i18next.changeLanguage(event.data.lang)
        localStorage.setItem("lang", event.data.lang)
      } else if (event.data && event.data.info && event.data.info === "videoDeletedSuccessfully") {
        setKey(iframeKey + 1);
      }
    });
  }, [])

	const handleCancel = () => {
		setvisible(false);
	};
	useEffect(() => {
		if (add) {
			const bucketUrlSplitted = window.process.env.BUCKET_URL_THUMBNAIL.split("/");
			const bucketUrl = bucketUrlSplitted?.slice(0, bucketUrlSplitted.length - 2)?.join("/");
			dispatch(
				setAddMediaActions({
					addMediaConstraintsDataNameChange: "logo",
					addMediaConstraintsDataValueChange: bucketUrl + "/" + getThumbByPath(),
				})
			);
		}
	}, []);

  useEffect(() => {
    setEndDate(values?.endDate);
    if (typeof (id) !== typeof undefined && typeof filename !== typeof undefined) {
      getThumbsthemes()
      getSubsGroups()
      getThemesByChannel();
      getContributors();
	   if(modules?.countries_video)
	  getCountries(i18n.language);
    }
  }, [id, filename])

 
  useEffect(() => {
    // const themesBychannels = themesList?.channels ? themesList?.channels : {}
    const formattedOptionsThemes = []
    let index = 0;

    const flatten = (arr, originalPos = 1, result = [], parent= true, child= false) => {
      for (let i = 0, length = arr.length; i < length; i++) {
        const value = arr[i];
        const pos = originalPos; 
        value.pos = pos;
        value.parent = parent;
        value.child = child;
        result.push(value);
        if (Array.isArray(value.themes) && value.themes.length > 0) {
          flatten(value.themes, pos + 1, result, false, true);
        }
      }
      return result;
    }
    const themesBychannels = themesList?.channels ? themesList?.channels : {};
    var channels = Object.values(themesBychannels);
    let result = [];
    for (let i = 0, length = channels.length; i < length; i++) {
      var value = channels[i];
      value.disabled = true;
      value.pos = 0;
      channels[i].themes = flatten(channels[i].themes);
      result = result.concat(channels[i]);
    }
    setOptionThemes(result)

  }, [themesList])


    const filterOption = (inputValue, option) => {
      if(option?.label){
        return option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
      }else{
        return option?.children[0]?.props?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
      }
    };
    
  useEffect(() => { if (values.isSecuredOnInternet === true) setPasswordVisible(true) }, [values])
  const [inputValue, setInputValue] = useState('');
  let filesVideo = values?.filesVideo && values?.filesVideo.length ? { uid: values?.filesVideo[0]?.id, url: values?.filesVideo[0]?.title, name: values?.filesVideo[0]?.fileName, status: "done" } : null
  const [FileUpload, setFileUpload] = useState(filesVideo !== null)
  const onChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    values?.keyWords?.length ? setTags(values?.keyWords?.split(" ")) : setTags([])
    setthemeoptvalues(values?.themes)
    setFileUpload(filesVideo !== null)
  }, [values]);



	const propsToUpload = {
		name: "empApiFileForm[thumb]",
		multiple: false,
		action: window.process.env.API_HOST_GLOBAL + "/video/video/upload/thumb",
		headers: {
			Authorization: "Bearer " + localStorage.getItem("authToken"),
		},
		data: (file) => {
			return {
				flowChunkNumber: 1,
				flowChunkSize: 1048576,
				flowCurrentChunkSize: file.size,
				flowTotalSize: file.size,
				flowIdentifier: file.size + file.name,
				flowFilename: file.name,
				flowRelativePath: file.name,
				flowTotalChunks: 1,
			};
		},
		beforeUpload: (file) => {
			const isImg =
				file.type === "image/jpeg" ||
				file.type === "image/jpg" ||
				file.type === "image/png" ||
				file.type === "image/gif";
			if (!isImg) {
				message.error(`${t("video.invalidFormat")}`, 10);
				return Upload.LIST_IGNORE;
			}
			const MAX_FILE_SIZE = 5000000;
			if (file.size > MAX_FILE_SIZE) {
				setShowUploadFile(false);
				setHandlErrorUploadMessage(true);
				return false;
			} else {
				setShowUploadFile(true);
				setHandlErrorUploadMessage(false);
				return true;
			}
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				setlocalthumbVideo(info.file.response.location.url);
			}
		},
		onDrop(e) {},
		accept: "image/*",
	};

	const setstatusmodal = (visible) => {
		setvisible(visible);
	};
	const checkTheme = () => {
		let _seletecthemes = Array.from(themeoptvalues, (row) => (row.value ? row.value : row));
		let themeThumb = [];
		for (let i in thumbspertheme?.thumbs) {
			themeThumb = _seletecthemes.find((value) => {
				return value == i;
			});
			if (themeThumb) {
				setlocalthumbVideo(thumbspertheme?.thumbs[themeThumb]);
				setselectedTheme("");
			}
		}
	};
	const findThumbPertheme = (theme_id) => {
		if (!theme_id) return;
		for (let i in thumbspertheme?.thumbs) {
			if (+i === theme_id && value === 3) {
				setlocalthumbVideo(thumbspertheme?.thumbs[i]);
				setselectedTheme("");
			}
		}
		if (!Object.keys(thumbspertheme?.thumbs).includes(theme_id?.toString())) {
			setselectedTheme("Aucune vignette pour les thèmes séléctionnées");
		}
	};
	const errorField = () => {
		if (!values.title) {
			message.error(`${t("titleRequired")}`, 10);
		}
		if (values.startDate > values.endDate) {
			message.error(`${t("invalidDate")}`, 10);
		}
		dispatch(setErrorsForm(false));
	};
	const [timeStamp, setTimeStamp] = useState(Date.now());
	useEffect(() => {
		if (loading === false) setTimeStamp(Date.now());
	}, [loading]);
	const getThumbByPath = () => {
		const splitFile = filename?.split(".")[0];
		let vignettes = splitFile + ".jpg";
		return vignettes;
	};
	const showThemeRubricTitle = () => {
		let Array = [t("video.Themes"), t("video.video_group")];
		if (window?.process?.env?.CLIENT_NAME) {
			const modifiedClientName = window.process.env.CLIENT_NAME.replace(/_/g, "-");

			if (modifiedClientName.includes("groupe-vyv")) {
				Array = [t("video.Rubric"), t("video.Thematic")];
			}
		}
		return Array;
	};

	return (
		<>
			{errorFormSubmit ? errorField() : null}
			<Modal
				className="custom-modal-metadata"
				title={<span title={values.title}>
					 {`${t("video.Metadata_video")}: ${values.title}`}
				 </span>} 
				onCancel={handleCancel}
				open={visible}
				footer={
					<Button
						onClick={() => {
							setstatusmodal(false);
						}}
					>
						{t("video.btn_close")}
					</Button>
				}
			>
				<Row>
					<Col className="gutter-row" span={20}>
						{" "}
						<b>{t("video.metadata_bitrate")}</b>{" "}
					</Col>{" "}
					{formatBytes(metadata?.format?.bit_rate) + "/s"}
				</Row>
				<Divider />
				<Row>
					<Col className="gutter-row" span={20}>
						{" "}
						<b>{t("video.resolution")}</b>{" "}
					</Col>
					{metadata?.streams && metadata?.streams[0]?.width} *{" "}
					{metadata?.streams && metadata?.streams[0]?.height}
				</Row>
				<Divider />
				<Row>
					<Col className="gutter-row" span={20}>
						{" "}
						<b>{t("video.size")}</b>{" "}
					</Col>{" "}
					{formatBytes(metadata?.format?.size)}{" "}
				</Row>
				<Divider />
				<Row>
					<Col className="gutter-row" span={20}>
						{" "}
						<b>{t("video.duration")}</b>
					</Col>
					{metadata &&
						metadata?.streams &&
						secondsToHms(
							metadata?.streams[0]?.duration
								? metadata?.streams[0]?.duration
								: metadata?.format?.duration
						)}
				</Row>
				<Divider />
				<Row>
					<Col className="gutter-row" span={20}>
						{" "}
						<b>{t("video.frame_rate")}</b>{" "}
					</Col>
					{metadata?.streams && metadata?.streams[0]?.avg_frame_rate}
				</Row>
				<Divider />
				<Row>
					<Col className="gutter-row" span={20}>
						{" "}
						<b>{t("video.codec_video")}</b>{" "}
					</Col>{" "}
					{metadata?.streams && metadata?.streams[0]?.codec_name}{" "}
				</Row>
				<Divider />
				<Row>
					<Col className="gutter-row" span={20}>
						{" "}
						<b>{t("video.Samplerate")}</b>{" "}
					</Col>
					{metadata?.streams && metadata?.streams[1]?.codec_time_base
						? (metadata?.streams[1]?.codec_time_base).split("/")[1]
						: "-"}
				</Row>
				<Divider />
				<Row>
					{" "}
					<Col className="gutter-row" span={20}>
						<b>{t("video.audio_codec")}</b>{" "}
					</Col>{" "}
					{metadata?.streams && metadata?.streams[1]?.codec_name
						? metadata?.streams[1]?.codec_name
						: "-"}{" "}
				</Row>
				<Divider />
				<Row>
					<Col className="gutter-row" span={20}>
						{" "}
						<b>{t("video.Audio_bitrate")}</b>{" "}
					</Col>
					{metadata?.streams && metadata?.streams[1]?.bit_rate
						? formatBytes(metadata?.streams[1]?.bit_rate) + "/s"
						: "-"}{" "}
				</Row>
			</Modal>
			<Row className="video-vignette-container">
				<Col xs={{ span: 24, offset: 0 }} md={{ span: 15, offset: 0 }} lg={{ span: 13, offset: 0 }}>
					{link ? (
						<iframe title="iframevideo" allowFullScreen src={"https:" + link} />
					) : (
						<Spin className="spinner"></Spin>
					)}
				</Col>

				<Divider className="divider-thumbnail" type="vertical" />

				<Col xs={22} sm={20} md={16} lg={7} xl={7} xxl={8}>
					<Row className="vignettediv">
						<Spin spinning={false}>
							<Col
								xs={{ span: 10, offset: 3 }}
								sm={{ span: 15, offset: 3 }}
								md={{ span: 12, offset: 3 }}
								lg={{ span: 24, offset: 0 }}
							>
								<img
									alt=""
									className="vignette"
									src={
										logo && logo !== null && logo !== ""
											? logo.split("/").length >= 4
												? logo
												: window.process.env.BUCKET_URL_THUMBNAIL + logo + "?v=" + timeStamp
											: !isvalid
												? defaultThumb
												: `${window.process.env.BUCKET_URL_THUMBNAIL}${getThumbByPath()}?v=${timeStamp}`
									}
								/>
							</Col>
						</Spin>
						<Col
							xs={{ span: 24, offset: 3 }}
							sm={{ span: 24, offset: 3 }}
							md={{ span: 24, offset: 3 }}
							lg={{ span: 24, offset: 0 }}
						>
							<Radio.Group className="paddingRadio" onChange={onChange}>
								<Radio value={1}>
									<b> {t("video.Choisir_une_vignette")}</b>
								</Radio>

                {
                  value === 1 ?
                    <div style={{ position: 'relative', marginLeft: '25px', marginTop: '7px' }}>
                      <Upload {...propsToUpload}
                        showUploadList={showUploadFile}
                        maxCount={1} >
                        <Button type="primary" icon={<UploadOutlined />}> Parcourir</Button>
                        {!showUploadFile && (
                          <p style={{ color: "red", fontSize: '10px' }}>
                            ( {t("video.maxSize")} <br />
                            {t("video.acceptedExtensions")}
                            <br /> JPEG, PNG, APNG, AVIF, GIF, SVG,
                            WebP
                            <br />{t("video.dimensionsAccepted")} <br />
                            480p (SD) : 720 x 480
                            <br />720p(HD) : 1280 x 720 <br />
                            1080p (HD) : 1920 x 1080 )
                          </p>
                        )}
                      </Upload> </div> : null
                }
                <Radio value={2}><b>{t("video.Capturer_vidéo")}</b></Radio>
                {
                  value === 2 ?
                    <div style={{
                      position: "relative",
                      marginLeft: "25px",
                      marginTop: "7px",
                      display: "flex",
                    }}>
                      <Spin spinning={loading} style={{ justifySelf: "center" }} >
                        <Button onClick={() => { getThumbsTime(filename, timevideoscreenthumbs); setScreenthumbs(true) }} type="primary" > {t("video.capture_vignette")}</Button></Spin></div> : null
                }
                <Radio onClick={() => { checkTheme() }} value={3}><b>{t("video.vignette_theme")}  </b> </Radio>
              </Radio.Group>
              {value === 3 ?
                <div style={{ position: 'relative', marginLeft: '25px', marginTop: '7px' }}>
                  {values?.themes?.length === 0 && <div style={{ position: 'relative', marginTop: '7px', color: 'red' }}><>{t("video.aucun_theme")}</></div>}
                  {values?.themes?.length > 0 && <div style={{ position: 'relative', marginTop: '7px', color: 'red' }}><>{selectedTheme}</></div>}
                </div> : null
              }
            </Col>

          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 15, offset: 0 }} lg={{ span: 11, offset: 0 }}>
          {(modules.media_video_get_video_meta_data || isSuperAdmin) && <Button has-permission="media_video_get_video_meta_data" type="text" onClick={() => { setstatusmodal(true) }} icon={<TagOutlined />} style={{ position: 'relative' }} >{t("video.Metadata_video")}</Button>}
        </Col>
      </Row>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={<><EditOutlined style={{ marginRight: '5px' }} />{t("video.video_Information")}</>} key="1">
          <Row className='form'>
            <Col span={24}>
              <Row gutter={[20, 0]} justify="space-between" >
                <Col span={12}>
                  <span style={{ fontFamily: "system-ui" }}>{t("video.title")}<span style={{ color: 'red' }}>*</span></span>
                  <Form.Item
                    name="title"
                    initialValue={values?.title}
                    rules={[{ required: true, message: 'Please input your title!' }]}
                  >
                    <Input onBlur={handleChangeInputs} key={values?.title} name={"title"} id={"title"} defaultValue={values?.title} value={values?.title} aria-placeholder="saisir un titre" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span style={{ fontFamily: "system-ui" }}>{t("video.update_date")}</span>
                  {/* there is a bug that made us use 2 Form.Item for the same field */}
                  {values?.updated_at ? <Form.Item name="updateDate">
                    <DatePicker showTime={{ format: 'HH:mm:ss' }} defaultValue={moment(values?.updated_at)} disabled allowClear={false} style={{ width: "100%" }} />
                  </Form.Item> : <Form.Item>
                    <DatePicker style={{ width: "100%" }} showTime={{ format: 'HH:mm:ss' }} disabled defaultValue={moment(new Date())} />
                  </Form.Item>}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {(modules.media_video_list_show_date || isSuperAdmin) && <Row has-permission="media_video_list_show_date" gutter={[0, 8]}>
                <Col span={24}>
                  <Row gutter={[20, 0]} justify="space-between">
                    <Col xs={24} sm={24} md={12}>
                      <span style={{ fontFamily: "system-ui" }}> {t("video.startdate")}<span style={{ color: 'red' }}>*</span></span>
                      <Form.Item initialValue={moment(values.startDate)} rules={[{ required: true, message: 'Please input Start Date!' }]} name="startDate" >
                        {values?.startDate
                          ? <DatePicker showTime={{ format: 'HH:mm:ss' }} defaultValue={moment(values?.startDate)} allowClear={false} onChange={(e) => { handleChangeDate(e, "startDate"); }} style={{ width: "100%" }} />
                          : <Form.Item> <DatePicker style={{ width: "100%" }} /> </Form.Item>
                        }
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span style={{ fontFamily: "system-ui" }}>{t("video.enddate")}<span style={{ color: 'red' }}>*</span></span>
                      <Form.Item initialValue={moment(enddate)} rules={[{ required: true, message: 'Please input End Date!' }]} name="endDate" >
                        {values?.endDate
                          ? <DatePicker showTime={{ format: 'HH:mm:ss' }} disabledDate={(current) => current && current < moment(values?.startDate)} defaultValue={moment(values?.endDate)} allowClear={false} onChange={(e) => { handleChangeDate(e, "endDate"); }} style={{ width: "100%" }} />
                          : <Form.Item> <DatePicker style={{ width: "100%" }} /> </Form.Item>
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>}
            </Col>
            <Col span={24}>
              <Row gutter={[20, 0]} justify="space-between">
                <Col xs={24} sm={24} md={12}>
                  <span style={{ fontFamily: "system-ui" }}>{showThemeRubricTitle()[0]}{isSuperAdmin ? null :<span style={{ color: 'red' }}>*</span>}</span>
                  {showthemes && 
               <Form.Item name="themes">
               <Select
                 showSearch
                 mode="multiple"
                 style={{ width: "100%" }}
                 defaultValue={showthemes && showthemes?.length !== 0 ?  showthemes.map(theme => theme.id) : []}
                 onChange={(e) => {setthemeoptvalues(e); updateThemes(e); findThumbPertheme(e[e.length - 1]?.value) }}
                 labelInValue={showthemes && showthemes?.length !== 0 ? Array.from(showthemes, (({ title }) => title)): []}
                 initialValue={showthemes?.length !== 0  ? showthemes?.map(theme => theme.title) : []}
                 filterOption={filterOption}
                 // open={true}
               >
                 {optionThemes?.map((canal) => (
                   <Select.OptGroup key={`${canal.id}`} label={canal.title} style={{fontWeight:"800"}}>
                     {canal.themes?.map((theme) => (
                       <Select.Option key={`${canal.id}-${theme.id}`} value={theme.id} className={theme.child ? 'select-themes' : ''} style={{ marginLeft: theme.pos * 20 + 'px'}}>
                         {theme?.parent && (
                           <span style={{ marginLeft: theme.pos * 20 + 'px' }}>
                             {theme?.title}
                           </span>
                         )}
                         {theme?.child && (
                           <span>&nbsp;&nbsp;&nbsp;&nbsp;{theme?.title}</span>
                         )}
                       </Select.Option>
                     ))}
                   </Select.OptGroup>
                 ))}
               </Select>
             </Form.Item>}
                </Col>
                {(modules.media_video_list_group_subscribers_list || isSuperAdmin) && <Col has-permission="media_video_list_group_subscribers_list" xs={24} sm={24} md={12} >
                  <span style={{ fontFamily: "system-ui" }}>{showThemeRubricTitle()[1]}</span>
                  {values.title && <Form.Item initialValue={Array.from(showthemes, (({ name }) => name))} name="subscriptionGroups" >
                    <Select
                      showSearch
                      placeholder={t("video.video_group")}
                      mode="multiple"
                      style={{ width: "100%" }}
                      onChange={(e) => updateSubsGroups(e)}
                      optionFilterProp="children"
                      defaultValue={values.subscriptionGroups}
                    >
                      {subsGroups?.length !== 0 && subsGroups?.map((group) => <Option key={group.id} value={group.id}>{group.name}</Option>)}

                    </Select>
                  </Form.Item>}
                </Col>}
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 8]}>

                {modules.media_video_secure_form_no_api || modules.media_home_post_disable_push_video || modules.media_video_downloadable_vid_form_no_api || modules.media_video_post_toggle || modules.media_video_password_form_no_api || isSuperAdmin ? <Col span={24}>
                  <b has-permission="media_video_secure_form_no_api,media_home_post_disable_push_video,media_video_downloadable_vid_form_no_api,media_video_post_toggle,media_video_password_form_no_api"  >{t("video.Confidentiality")}</b>
                  <Row gutter={[0, 8]} justify="space-between">

                    <Row className="confidentialité-checkbox">
                      <Form.Item name="confidentialité" >
                        {CheckboxConf.map((row) => {
                          return (row.permission || isSuperAdmin) &&
                            <Checkbox key={row.name} style={{ marginLeft: 0 }} checked={values[row.name]} onChange={(e) => {
                              if (row.name === "isSecuredOnInternet") {
                                setPasswordVisible(e.target.checked)
                                updateConfValues("secure", e.target.checked)
                              } updateConfValues(row.name, e.target.checked)
                            }} ><span has-permission={row.permissionlabel} >{row.label}</span></Checkbox>
                        })}
                        {(values.isPrivate && (modules.media_video_private || isSuperAdmin)) &&
                          <Col style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "-10px" }}>
                            <span style={{ fontFamily: "system-ui", width: "120px" }}>
                              {t("video.video_group")} :
                            </span>
                            {values.title && <Form.Item name="subscriptionGroups" >
                              <Select
                                showSearch
                                placeholder={t("video.video_group")}
                                mode="multiple"
                                style={{ width: "100%" }}
                                onChange={(e) => updateSubsGroups(e)}
                                optionFilterProp="children"
                                defaultValue={values.subscriptionGroups}
                              >
                                {subsGroups?.length !== 0 && subsGroups?.map((group) => <Option key={group.id} value={group.id}>{group.name}</Option>)}

                              </Select>
                            </Form.Item>}
                          </Col>
                        }
                      </Form.Item>
                    </Row>
                    <Col xs={24} sm={24} md={12}>
                      {passwordVisible && values.passwordInternet && <Form.Item initialValue={values.passwordInternet} rules={[
                        {
                          required: true,
                          message: 'Please input the password!',
                        },
                      ]} name="passwordInternet" label={t("Profile.addPassword")}>

                        <Input.Password
                          autz
                          placeholder={t("Profile.updatePassword")}
                          onBlur={handleChangeInputs}
                          style={{ width: "95.4%" }}
                          name="passwordInternet"
                          initialValue={values.passwordInternet} autocomplete="off"
                          defaultValue={values.passwordInternet} value={values.passwordInternet}
                          visibilityToggle={{ visible: passwordVisibleiText, onVisibleChange: setPasswordVisibleText }}
                        />


                      </Form.Item>}
                      {passwordVisible && !values.passwordInternet && <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input the password!',
                        },
                      ]} name="passwordInternet" label={t("Profile.addPassword")}>

                        <Input.Password
                          placeholder={t("Profile.updatePassword")}
                          onBlur={handleChangeInputs}
                          style={{ width: "95.4%" }}
                          name="passwordInternet"
                          initialValue={values.passwordInternet} autocomplete="off"
                          defaultValue={values.passwordInternet} value={values.passwordInternet}
                          visibilityToggle={{ visible: passwordVisibleiText, onVisibleChange: setPasswordVisibleText }}
                        />



                      </Form.Item>}
                    </Col>
                  </Row>
                </Col> : null}
                <Col>
                </Col>
              </Row>
            </Col>

						{customFieldsAPI && (
							<Card>
								<CustomFields
									interface="Video"
									itemId={+id}
									apiUrl={customFieldsAPI}
									token={localStorage.getItem("authToken")}
									language={localStorage.getItem("i18nextLng")}
									update={updatedCustomFields}
								/>
							</Card>
						)}
					</Row>
				</Tabs.TabPane>
				<Tabs.TabPane
					tab={
						<>
							<SettingOutlined style={{ marginRight: "5px" }} />
							{t("video.additional_info")}
						</>
					}
					key="2"
				>
					<AdditionalInfo
						inputValue={inputValue}
						setInputValue={setInputValue}
						values={values}
						setFileUpload={setFileUpload}
						FileUpload={FileUpload}
						tags={tags}
						setTags={setTags}
						Option={Option}
					/>
				</Tabs.TabPane>
			</Tabs>
		</>
	);
};
