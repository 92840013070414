import { useState, useEffect } from "react";
import { Row, Input, Button, Divider, Tabs, Modal, message, Form, Space } from "antd";
import French from "@uppy/locales/lib/fr_FR";
import English from "@uppy/locales/lib/en_US";
import Files from "../utils/Files";
import {
  YoutubeFilled,
  PlayCircleFilled,
  FolderAddFilled,
  CameraFilled,
  LinkOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { AddHooks } from "../utils/hooks";
import { useHistory, useLocation } from "react-router-dom";
import Hooks from "../../Medias/utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import useDataTablePendingVideo from "./pendingVideoTable";
import { useAppSelector } from "../../redux/hooks";
import { DataPendingVideosTable } from "../data/dataVideosPending";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import i18next from "i18next";
import { AddUrl } from "./AddUrl";

import {
  setAddMediaActions,
  setIdVideoAdded,
  setLoading,
  setTabClicked,
} from "../store/addMediaActions";
import Loader from "../../components/Loader";
import { getUserData } from "../../userData/userDataActions";
import { StatusBar, DragDrop } from "@uppy/react";
import LoadingScreen from "../../Podcast/utils/LoadingScreen";

export const ExportVideo = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { columns, data } = DataPendingVideosTable();

  const { DataTable } = useDataTablePendingVideo({
    columns: columns,
    dataSource: data,
  });
  const [fileCountUpload, SetfileCountUpload] = useState(0);
  const [defaultKey, setdefaultKey] = useState("1");
  const [Link, setLink] = useState("");
  const [currentuploading, setcurrenUploading] = useState(0);
  const [listtoupload, setlisttoupload] = useState([]);
  const [, setisFinishedUpload] = useState(false);
  const [automaticAdd, setautomaticAdd] = useState(false);
  const [loaderLoading, setLoaderLoading] = useState(false);
  const messageCatchCurrupted = (() => message.error(`Cette vidéo est corrompue `))
  const handleChange = (e) => {
    const { checked } = e.target;
    setautomaticAdd(checked);
  };

  useEffect(() => {
    localStorage.setItem("automaticAdd", automaticAdd);
  }, [automaticAdd]);

  function makeid(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const error = () => {
    Modal.error({
      title: "Réssayer ultérieurement",
      content: "Une erreur s'est produite de notre côté ",
    });
  };
  const removeSpecialCharFromName = (name) => {
    return name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[&\/\\#,+()$~%.'":*?<>{}^;]/g, "")
        .replace(/\s+/g, "_"); 
  }
  const uppy = new Uppy({
    debug: true,
    locale: i18next.language == "en" ? English : French,
    id: "uppy",
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: [
        ".mp4",
        ".flv",
        ".m4v",
        ".avi",
        ".flv",
        ".wmv",
        ".mpeg",
        ".mpg",
        ".mpe",
        ".dat",
        ".vob",
        ".asf",
        ".asx",
        ".mov",
        ".3gp",
        ".3g2",
        ".3gpp",
        ".dv",
        ".qt",
        ".rm",
        ".webm",
        ".mkv"
      ],
    },
    onBeforeUpload: (files) => {
      const updatedFiles = Object.assign({}, files);
      Object.keys(updatedFiles).forEach((fileId) => {
        let lastDotPosition = updatedFiles[fileId].name.lastIndexOf(".");
        let name = updatedFiles[fileId].name.slice(0, lastDotPosition);
        name = removeSpecialCharFromName(name);
        let ext = updatedFiles[fileId].name.slice(
          lastDotPosition,
          updatedFiles[fileId].name.length
        );
        let filename = name + makeid(5) + ext;
        const changeSourceHeaders =
          query.get("changeSource") === "true"
            ? {
              "upload-type": "change-source",
              "video-id": query.get("id"),
              "media-id": query.get("mediaid"),
              token: query.get("fileName").split(".")[0],
            }
            : {};
        let headers = {
          "automatic-add":
            query.get("changeSource") === "true" ? false : automaticAdd,
          Authorization: "Bearer " + localStorage.getItem("authToken"),
          autoUpload: false,
          filename:
            query.get("changeSource") === "true"
              ? query.get("fileName")
              : filename,
          "client-name": window.process.env.CLIENT_NAME,
          ...changeSourceHeaders,
        };
        updatedFiles[fileId].tus = {
          headers: headers,
        };
      });
      return updatedFiles;
    },
    autoProceed: true,
  });
  uppy.use(Tus, {
    chunkSize: 1024 * 1024 * 50,
    endpoint: `${window.process.env.API_HOST_UPLOAD}${query.get("changeSource") ? "videos/change-source" : "videos/upload-video"
      }`,
    removeFingerprintOnSuccess: true,
    async onAfterResponse(req, res) {
      res?.getBody() && Files.setToken(res?.getBody());
      res?.getBody() && Files.setVideoId(res?.getBody());
    },
  });
  uppy.on("complete", async (result) => {
    if (result?.successful?.length) {
        let videoToken = Files.getToken();
        let tokensData = [{ token: videoToken }];
        dispatch(
            setAddMediaActions({
                addMediaConstraintsDataNameChange: "tokens",
                addMediaConstraintsDataValueChange: tokensData,
            })
        );
        let videoId = Files.getVideoId();
        let filename = result?.successful[0]?.tus?.headers?.filename;
        dispatch(setIdVideoAdded(videoId));
        if (automaticAdd) {
            history.push("/ws-media/medias");
        } else {
            if (query.get("changeSource") === "true" && videoToken) {
                return history.push("/ws-media/medias");
            }
            setLoaderLoading(true);

            let metadataSuccess = false; 
            try {
                await getMetaData(videoId, filename, false);
                metadataSuccess = true;
            } catch (error) {
                setLoaderLoading(false);

                messageCatchCurrupted()
            }
            if (metadataSuccess) {
                try {
                    await getThumbs(filename);
                    setLoaderLoading(false);
                    videoToken
                        ? history.push({
                            pathname: `/ws-media/secondpageupload/video/${filename}/${videoId}`,
                            state: { id: data, videoId: videoId },
                        })
                        : error();
                } catch (error) {
                    console.error("Error getting thumbs:", error);
                }
            }
        }
        setisFinishedUpload(true);
        setcurrenUploading(currentuploading + 1);
        setlisttoupload([
            ...listtoupload,
            {
                fileName: result?.successful[0]?.name,
                size: result?.successful[0]?.size,
                id: fileCountUpload + 1,
            },
        ]);
        setisFinishedUpload(true);
        SetfileCountUpload(fileCountUpload + 1);
    }
});


  uppy.on("upload-error", async () => {
    Object.keys(localStorage).forEach((ls) => {
      if (ls.includes("tus")) {
        localStorage.removeItem(ls);
      }
    });
    dispatch(setLoading(false));
  });

  uppy.on("restriction-failed", (file, error) => {
    message.error(`${error}`);
  });

  useEffect(() => {
    dispatch(setTabClicked(0));
  }, []);
  const modules = useSelector((state) => state.ReducerUserData.modules);
  const isSuperAdmin = useSelector(
    (state) => state.ReducerUserData.isSuperAdmin
  );

  const loading = useSelector((state) => state.ReducerUserData.loading);

  const {
    extractDataVideo,
    getContributors,
    getThemesByChannel,
    getMetaData,
    getThumbs,
  } = AddHooks();
  const { handleSecondAddMedia } = Hooks();
  const history = useHistory();
  const storyboardURL = useAppSelector(
    (state) => state.addMediaReducer.storyboard
  );

  const onKeyChange = (key) => {
    setdefaultKey(key);
    dispatch(setTabClicked(key));
  };
  const { TabPane } = Tabs;
  const exportType = [
    {
      id: 1,
      title: `${t("video.Importer")}`,
      icon: <FolderAddFilled />,
      permission: true,
      value: (
        <>
          <Loader loaderStatus={loaderLoading} />
          <h2> {t("video.importer_fichiers")} </h2>
          <DragDrop
            id="dragdrop"
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: t("video.Select_files"),
              },
            }}
            inline={true}
            metaFields={[]}
            replaceTargetContent={true}
            note={t("video.noteupload")}
            target={document.body}
            proudlyDisplayPoweredByUppy={false}
            showProgressDetails={true}
            showSelectedFiles={true}
          />
          <StatusBar
            uppy={uppy}
            hideUploadButton
            hideAfterFinish={false}
            showProgressDetails
          />
          {query.get("changeSource") === "true" ? null : (
            <FormControlLabel
              className="input"
              control={<Checkbox />}
              checked={automaticAdd}
              name={"automaticAdd"}
              onChange={handleChange}
              label={t("Podcast.Automatic upload")}
            />
          )}
        </>
      ),
    },
    {
      id: 2,
      title: (
        <span has-permission="media_video_add_option_on_hold">
          {" "}
          {`${t("video.Medias_en_attente")}`}
        </span>
      ),
      icon: <PlayCircleFilled />,
      value: <DataTable />,
      permission: modules.media_video_add_option_on_hold,
    },
    {
      id: 3,
      title: (
        <span has-permission="media_video_add_option_extern_link">{`${t(
          "video.Lien"
        )}`}</span>
      ),
      icon: <LinkOutlined />,
      permission: modules.media_video_add_option_extern_link,
      value: (
       <AddUrl />
      ),
    },

    {
      id: 8,
      title: (
        <span has-permission="media_video_add_option_webcam">
          {t("video.camera")}
        </span>
      ),
      icon: <CameraFilled />,
      disabled: true,
      permission: modules.media_video_add_option_webcam,
      value: <></>,
    },
    {
      id: 10,
      title: (
        <span has-permission="media_video_add_option_story_board">
          Storyboard
        </span>
      ),
      icon: <YoutubeFilled />,
      disabled: true,
      permission: modules.media_video_add_option_webcam,
      value: (
        <iframe
          title={storyboardURL}
          className="iframeVideo"
          id="iframe_display"
          src={"https:" + storyboardURL}
          allowFullScreen
        ></iframe>
      ),
    },
  ];
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="upload-page">
          <ArrowLeftOutlined
            style={{
              cursor: "medium",
              padding: "10px",
              margin: 0,
              position: "absolute",
              top: "5px",
              left: "5px",
            }}
            onClick={() => {
              history.push("/ws-media");
              window.parent.postMessage(
                { info: "diffusion", value: "ongoing" },
                "*"
              );
            }}
          />

          <Tabs
            defaultActiveKey={defaultKey}
            activeKey={defaultKey}
            onChange={onKeyChange}
          >
            {exportType &&
              exportType.length &&
              exportType.map(
                (element) =>
                  (isSuperAdmin || element.permission) && (
                    <TabPane
                      disabled={element.disabled}
                      tab={
                        <div>
                          <span>
                            {element.icon}
                            {element.title}
                          </span>
                          <Divider type="vertical" />
                        </div>
                      }
                      key={element.id}
                    >
                      {element.value}
                    </TabPane>
                  )
              )}
          </Tabs>
        </div>
      )}
    </>
  );
};
