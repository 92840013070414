import Tus from "@uppy/tus";
import Uppy from "@uppy/core";
import French from "@uppy/locales/lib/fr_FR";
import English from "@uppy/locales/lib/en_US";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadTus,
  cleanPodcastVideo,
  getVideoToken,
  getPodcastVideo_onUpload,
  handleUploadCancel,
  Automatic_upload_validation,
} from "../../utils/action";
import { useHistory, useLocation } from "react-router-dom";
import { Arrowback } from "@empreinte/components.arrowback";
import PodcastClass from "./const";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Checkbox } from "@empreinte/components.checkbox";
import { useState, useEffect } from "react";
import "../table.css";
import "../../Pages/podcast.scss";
import { message } from "antd";
import { Helpers } from "../../../utils/helpers";

const Export = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { acces_send } = Helpers();
  const Id = useSelector((state) => state.ReducerGetPodcastData.tus_id);
  const [automaticAdd, setautomaticAdd] = useState(false);

  const handleChange = (e) => {
    const { checked } = e.target;
    setautomaticAdd(checked);
  };

  useEffect(() => {
    dispatch(Automatic_upload_validation(automaticAdd));
  }, [automaticAdd]);

  useEffect(() => {
    acces_send("podcast_post_add_action");
  }, []);
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { StatusBar, DragDrop } = require("@uppy/react");

  function makeid(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const removeSpecialCharFromName = (name) => {
    return name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[&\/\\#,+()$~%.'":*?<>{}^;]/g, "")
      .replace(/\s+/g, "_");
  };
  const uppy = new Uppy({
    debug: true,
    locale: i18next.language == "en" ? English : French,
    id: "uppy",
    allowMultipleUploadBatches: true,

    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: [
        "audio/*",
        "video/*",
        ".flv",
        ".avi",
        ".wmv",
        ".mpeg",
        ".mpg",
        ".mpe",
        ".dat",
        ".vob",
        ".asf",
        ".asx",
        ".mov",
        ".3gp",
        ".3g2",
        ".3gpp",
        ".dv",
        ".qt",
        ".rm",
      ],
    },
    onBeforeUpload: (files) => {
      const updatedFiles = Object.assign({}, files);
      Object.keys(updatedFiles).forEach((fileId) => {
        let lastDotPosition = updatedFiles[fileId].name.lastIndexOf(".");
        let name = updatedFiles[fileId].name.slice(0, lastDotPosition);
        name = removeSpecialCharFromName(name);
        let ext = updatedFiles[fileId].name.slice(
          lastDotPosition,
          updatedFiles[fileId].name.length
        );
        let filename = name + makeid(5) + ext;
        let filenameAudio = filename.split(".").at(-2) + ".mp3";
        dispatch(getVideoToken(filename));
        dispatch(getPodcastVideo_onUpload(filename));
        dispatch(uploadTus(filenameAudio));

        const changeSourceHeaders =
          query.get("changeSource") === "true"
              ? {
                "upload-type": "change-source",
                "podcast-id": query.get("id"),
                "item-id": query.get("itemId"),
                token: query.get("fileName").split(".")[0],
              }
          : {};
        let headers = {
          "automatic-add": automaticAdd,
          Authorization: "Bearer " + localStorage.getItem("authToken"),
          autoUpload: false,
          filename: query.get("changeSource") === "true" ? query.get("fileName") : filename,
          ...changeSourceHeaders,
          "client-name": window.process.env.CLIENT_NAME,
        };
        updatedFiles[fileId].tus = {
          headers: headers,
        };
      });
      return updatedFiles;
    },
    autoProceed: true,
  });
  uppy.use(Tus, {
    chunkSize: 1024 * 1024 * 100,
    endpoint: window.process.env.API_HOST_UPLOAD + (query.get("changeSource") ? "podcasts/change-source" : "podcasts/upload-podcast"),
    removeFingerprintOnSuccess: true,
    async onAfterResponse(req, res) {
      res?.getBody() && PodcastClass.setId(res?.getBody());
    },
  });
  uppy.on("upload-error", async () => {
    Object.keys(localStorage).forEach((ls) => {
      if (ls.includes("tus")) {
        localStorage.removeItem(ls);
      }
    });
  });
  uppy.on("restriction-failed", (file, error) => {
    message.error(`${error}`);
  });
  uppy.on("complete", async (result) => {
    if (query.get("changeSource") === "true") {
      return history.push("/ws-media/podcasts");
    }
    if (result?.successful?.length) {
      if (!automaticAdd) {
        history.push("/ws-media/upload");
      } else history.push("/ws-media/podcasts");
    }
  });
  uppy.on("file-removed", async () => {
    Id && dispatch(handleUploadCancel(Id));
  });

  return (
    <div className="uppyC">
      <div className="go-to-main-page">
        <Arrowback
          className="gobackIcon"
          action={() => {
            history.push("/ws-media/podcasts");
            dispatch(cleanPodcastVideo());
          }}
        />

        <h2>{t("Podcast.add-edit.Import files")}</h2>
      </div>

      <DragDrop
        uppy={uppy}
        inline={true}
        locale={i18next.language == "en" ? English : French}
        metaFields={[]}
        plugins={["GoogleDrive", "Webcam"]}
        replaceTargetContent={true}
        locale={{
          strings: {
            dropHereOr: t("Podcast.Select files"),
          },
        }}
        note={t("video.noteupload_podcast")}
        target={document.body}
        proudlyDisplayPoweredByUppy={false}
        showProgressDetails={true}
        showSelectedFiles={true}
      />
      <StatusBar
        uppy={uppy}
        hideUploadButton
        hideAfterFinish={false}
        showProgressDetails
      />
      <div className="input">
        <Checkbox
          label={t("Podcast.Automatic upload")}
          checked={automaticAdd}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Export;
