import { PodcastList } from "./PodcastConstant";
import {TYPES} from "./types";
const { PodcastCondition, AddPodcastItems, PodcastData, paginationProps } =
  PodcastList();
const initialState = {
	podcast_data: [],
	totalPodcasts: 0,
	Rrs_link: "",
	Upload_data: [],
	loading: false,
	id: null,
	Podcast_info: [],
	deletePodcastData: [],
	addPodcastData: [],
	videolink: "",
	getPodcast_data_byID: [],
	empParametersPodcastForm: {},
	onDelete: null,
	onMultiDeleteData: [],
	MultiDeleteId: [],
	searchKeyWord: "",
	SearchResult: [],
	videoDownloadLink: "",
	videoSrc: "",
	SelectChoices: [],
	LanguageSelet: "",
	categorySelect: "",
	areTheyDoneTranscoding: [],
	videoDownLoad: "",
	updatePodcastData: "",
	Statstoken: "",
	thumbData: "",
	secondtHumb: "",
	secondThumbData: "",
	thumbSrc: "",
	podcastItems: {},
	podcastAudio: "",
	podcastVideo: "",
	tus_id: "",
	podcastItems_byId: [],
	IdList: [],
	toggleConstant: false,
	isDeleted: false,
	doneDelete: false,
	singlePodcastDelete: false,
	doneDeleteSinglePod: false,
	success_rss: false,
	success_details: false,
	success_edit: false,
	success_preview: false,
	success_download: false,
	on_loading_screen: true,
	on_redirect: false,
	on_automatic_add: false,
	unauthorizedPodcast: true,
	redirectPostUpload: false,
	FilterList: "online",
	isItemRetored: false,
	isPodcastEdited: false,
	isPodcastAdded: false,
	exportLinks: "",
	playerById: "",
	PodcastCondition: PodcastCondition(),
	paginationProps: paginationProps(),
	AddPodcastItems: AddPodcastItems(),
	podcastData: PodcastData(),
	podcastVideoToken: "",
	listVideo: "",
	totalCount: "",
	searchPlaylist:"",
	
};

const ReducerGetPodcastData = (state = initialState, action) => {

    switch (action.type) {
			case TYPES.GET_LANGUAGE_SELECT_VALUE:
				return {
					...state,
					LanguageSelet: action.payload,
				};
			case TYPES.RESTORE_DELETE_PODCAST:
				return {
					...state,
					isItemRetored: true,
				};
			case TYPES.redirectMainPage:
				return {
					...state,
					redirectPostUpload: action.payload,
				};
			case TYPES.ON_AUTOMATIC_ADD:
				return {
					...state,
					on_automatic_add: action.payload,
				};
			case TYPES.ON_REDIRECT:
				return {
					...state,
					on_redirect: action.payload,
				};

			case TYPES.ON_LOADING_SCREEN:
				return {
					...state,
					on_loading_screen: action.payload,
				};
			case TYPES.GET_PODCAST_DATA_FAILED:
				return {
					...state,
					on_loading_screen: false,
					unauthorizedPodcast: true,
				};
			case TYPES.ON_LOAD_DOWNLOAD:
				return {
					...state,
					success_download: action.payload,
				};
			case TYPES.ON_LOAD_PREVIEW:
				return {
					...state,
					success_preview: action.payload,
				};
			case TYPES.ON_LOAD_EDIT:
				return {
					...state,
					success_edit: action.payload,
				};
			case TYPES.ON_LOAD_DETAILS:
				return {
					...state,
					success_details: action.payload,
				};
			case TYPES.ON_SUCCES_RSS:
				return {
					...state,
					success_rss: action.payload,
				};
			case TYPES.on_test:
				return {
					...state,
					log: action.payload,
				};
			case TYPES.single_podcast_delete:
				return {
					...state,
					singlePodcastDelete: action.payload,
				};
			case TYPES.done_DeleteSinglePod:
				return {
					...state,
					doneDeleteSinglePod: action.payload,
				};
			case TYPES.done_Delete:
				return {
					...state,
					doneDelete: action.payload,
				};
			case TYPES.is_Deleted:
				return {
					...state,
					isDeleted: action.payload,
				};
			case TYPES.TOGGLE_CONSTANT:
				return {
					...state,
					toggleConstant: action.payload,
				};
			case TYPES.ON_SEND_PODCAST_LIST_ID:
				return {
					...state,
					IdList: action.payload,
					areTheyDoneTranscoding: action.payload,
				};
			case TYPES.ON_GET_PODCAST_ITEMS_BYID:
				return {
					...state,
					podcastItems_byId: action.payload,
				};
			case TYPES.ON_GET_SECOND_THUMB_DATA_BY_ID:
				return {
					...state,
					data_podcastItem_ByID: action.payload,
				};
			case TYPES.GET_PODCAST_VIDEO_ON_UPLOAD:
				return {
					...state,
					podcastVideo: action.payload,
				};
			case TYPES.ON_SEND_TUS_ID:
				return {
					...state,
					tus_id: action.payload,
				};
			case TYPES.GET_PODCAST_AUDIO:
				return {
					...state,
					podcastAudio: action.payload,
				};
			case TYPES.ON_ADD_PODCASTITEM:
				return {
					...state,
					podcastItems: action.payload,
				};

			case TYPES.MAIN_PAGE_SECOND_THUMB:
				return {
					...state,
					secondtHumb: action.payload,
				};
			case TYPES.UPLOAD_MAIN_PAGE_SECOND_THUMB:
				return {
					...state,
					secondThumbData: action.payload,
				};
			case TYPES.GET_THUMB_ON_ADDPODCAST:
				return {
					...state,
					thumbSrc: action.payload,
				};
			case TYPES.ON_UPLOAD_THUMB:
				return {
					...state,
					thumbData: action.payload,
				};

			case TYPES.GET_STATS_TOKEN:
				return {
					...state,
					Statstoken: action.payload,
				};
			case TYPES.ON_UPLOAD_THUMB:
				return {
					...state,
					Thumbnail: action.payload,
				};
			case TYPES.ON_MULTI_DELETE_DATA:
				return {
					...state,
					onMultiDeleteData: action.payload,
				};
			case TYPES.UPDATE_PODCAST_DATA:
				return {
					...state,
					updatePodcastData: action.payload,
				};

			case TYPES.ARE_THEY_DONE_TRANSCODING:
				let listIds = state.IdList.filter(
					(el) => !Array.from(action.payload, ({ id }) => id).includes(el)
				);
				return {
					...state,
					areTheyDoneTranscoding: listIds,
				};
			case TYPES.GET_CATEGORY_SELECT_VALUE:
				return {
					...state,
					categorySelect: action.payload,
				};
			case TYPES.GET_SELECT_CHOICES:
				return {
					...state,
					SelectChoices: action.payload,
				};
			case TYPES.GET_VIDEO_SRC:
				return {
					...state,
					videoSrc: action.payload,
				};
			case TYPES.GET_VIDEO_DOWNLOAD_LINK:
				return {
					...state,
					videoDownloadLink: action.payload,
				};
			case TYPES.GET_PODCAST_DATA:
				return {
					...state,
					podcast_data: action.payload,
					unauthorizedPodcast: false,
				};
			case TYPES.GET_PODCAST_RECORD:
				return {
					...state,
					totalPodcasts: action.payload,
				};
			case TYPES.SET_SEARCH_RESULT:
				return {
					...state,
					SearchResult: action.payload,
				};
			case TYPES.GET_BY_SEARCHKEYWORD:
				return {
					...state,
					searchKeyWord: action.payload,
				};
			case TYPES.Filter:
				return {
					...state,
					FilterList: action.payload,
				};
			case TYPES.MULTI_DELETE_ID:
				return {
					...state,
					MultiDeleteId: action.payload,
				};
			case TYPES.ON_MULTI_DELETE_BOOLEAN:
				return {
					...state,
					onDelete: action.payload,
				};
			case TYPES.GET_EMP_PARAMETERS_FORM:
				return {
					...state,
					empParametersPodcastForm: action.payload,
				};

			case TYPES.GET_RRS_LINK:
				return {
					...state,
					Rrs_link: action.payload,
				};

			case TYPES.SET_LOADING:
				return {
					...state,
					loading: action.payload,
				};
			case TYPES.UPLOAD_FILE_PROGRESS:
				return {
					...state,
					Upload_data: action.payload,
				};
			case TYPES.SET_ID:
				return {
					...state,
					id: action.payload,
				};
			case TYPES.PODCAST_INFO:
				return {
					...state,
					Podcast_info: action.payload,
				};

			case TYPES.DELETE_PODCAST:
				return {
					...state,
					deletePodcastData: action.payload,
				};

			case TYPES.ADD_PODCAST:
				return {
					...state,
					addPodcastData: action.payload,
				};
			case TYPES.GET_PODCAST_VIDEO:
				return {
					...state,
					videolink: action.payload,
				};
			case TYPES.GET_PODCAST_BY_ID:
				return {
					...state,
					AddPodcastItems: action.payload,
				};
			case TYPES.SET_EDIT_PODCAST:
				return {
					...state,
					isPodcastEdited: action.payload,
				};
			case TYPES.SET_ADDED_PODCAST:
				return {
					...state,
					isPodcastAdded: action.payload,
				};
			case TYPES.SET_PODCAST_CONDITION:
				const { PodcastConditionsName, PodcastConditionsValue } = action.payload;
				const PodcastConditionsObj = {
					...state.PodcastCondition,
					[PodcastConditionsName]: PodcastConditionsValue,
				};
				return {
					...state,
					loading: false,
					PodcastCondition: PodcastConditionsObj,
				};
			case TYPES.SET_PAGINATION_PROPS:
				const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload;
				return {
					...state,
					paginationProps: {
						...state.paginationProps,
						[PaginationPropsNameChange]: PaginationPropsValueChange,
					},
				};
			case TYPES.SET_PODCAST_CONDITION_ADD:
				const { addPodcastDataNameChange, addPodcastDataValueChange } = action.payload;
				return {
					...state,
					loading: false,
					AddPodcastItems: {
						...state.AddPodcastItems,
						[addPodcastDataNameChange]: addPodcastDataValueChange,
					},
				};
			case TYPES.SET_PODCAST_THEMES:
				return {
					...state,
					loading: false,
					AddPodcastItems: {
						...state.AddPodcastItems,
						Themes: action.payload.newValue.map((value) => {
							return {
								ThemeId: value.value,
								ThemeTitle: value.label,
							};
						}),
					},
				};
			case TYPES.SET_PODCAST_ITEMS:
				return {
					...state,
					loading: false,
					podcastItems: action.payload,
				};
			case TYPES.CLEAR_PODCAST_CONDITION:
				return {
					...state,
					loading: false,
					AddPodcastItems: AddPodcastItems(),
				};
			case TYPES.CLEAR_PODCAST_THEMES:
				return {
					...state,
					loading: false,
					AddPodcastItems: { ...state.AddPodcastItems, Themes: [] },
				};
			case TYPES.SET_PODCAST_THUMBNAIL:
				return {
					...state,
					loading: false,
					AddPodcastItems: {
						...state.AddPodcastItems,
						Vignette: action.payload,
					},
				};
			case TYPES.GET_PODCASTS_DATA:
				return {
					...state,
					on_loading_screen: false,
					unauthorizedPodcast: false,
					podcastData: action.payload,
				};
			case TYPES.GET_EXPORT_LINKS:
				return {
					...state,
					exportLinks: action.payload,
				};
			case TYPES.GET_VIDEO_TOKEN:
				return {
					...state,
					podcastVideoToken: action.payload,
				};
			case TYPES.GET_PLAYER_BY_ID:
				return {
					...state,
					playerById: action.payload,
				};
			case TYPES.GET_LIST_VIDEO:
				return {
					...state,
					listVideo: action.payload,
				};
			case TYPES.GET_TOTAL_COUNT:
				return {
					...state,
					totalCount: action.payload,
				};
			case TYPES.SET_SEARCH_WORD:
				return {
					...state,
					searchPlaylist: action.payload,
				};
			default:
				return state;
		}
}


export default ReducerGetPodcastData;
